import React, { forwardRef, useRef, useCallback, useEffect } from 'react';
import composeRefs from '@seznam/compose-react-refs';


export default forwardRef(function HelpDocument({
  content,
  url
}, externalRef) {

  const helpDocumentRef = useRef(null);

  const scrollOnFrameTop = useCallback(() => {
    if (!!helpDocumentRef.current) {
      window.scrollTo({ top: helpDocumentRef.current.getBoundingClientRect().top, behavior: 'smooth' });
    }
  }, []);

  const handleHelpDocumentLoad = useCallback(() => {
    if (!!helpDocumentRef.current) {
      helpDocumentRef.current.style.minHeight = `${window.innerHeight}px`;

      scrollOnFrameTop();
    }
  }, [scrollOnFrameTop]);

  useEffect(() => {
    if (!!helpDocumentRef.current) {
      scrollOnFrameTop();
    }
  }, [scrollOnFrameTop]);

  return (
    <object
      width="100%"
      id="helpDoc"
      type="application/pdf"
      ref={composeRefs(helpDocumentRef, externalRef)}
      data={`/assets/docs/${url}#view=FitH`}
      onLoad={handleHelpDocumentLoad}
    >
      <p>
        It appears your Web browser is not configured to display PDF files.
            No worries, just <a href={`/assets/docs/${url}`}>click here to download the PDF file.</a>
      </p>
    </object>
  )
});