import { useMemo, useCallback } from 'react';

import { useFetch, useFetchDeferred } from '~/lib/Effects.Fetch';

const API_AUTH = 'auth';
const API_TOKEN = 'token';

export function usePostUserLogIn() {
  const options = useMemo(() => {
    return ({
      method: 'POST',
      path: `${API_AUTH}/login`,
      data: null // will be set on post()
    });
  }, []);

  return useFetchDeferred(options);
}

export function useFetchUserLayers(user) {
  const url = useMemo(() => {
    if (!user) {
      return null;
    }
    return `userlayers/${user.id}`;
  }, [user]);

  const handleFetchUserLayerResponse = useCallback((response) => {
    return (!!response && !!response.userlayers && response.userlayers.userLayers) || null;
  }, []);

  return useFetch(url, handleFetchUserLayerResponse);
}

export function useDeleteUserLayer() {
  const options = useMemo(() => {
    return ({
      method: 'DELETE',
      path: 'userlayer',
      query: ''
    });
  }, []);

  return useFetchDeferred(options);
}

export function usePostUserSignUp() {
  const options = useMemo(() => {
    return ({
      method: 'POST',
      path: `${API_AUTH}/register`,
      data: null
    });
  }, []);

  return useFetchDeferred(options);
}

export function useFetchUserProfiles() {
  const url = useMemo(() => {
    return `${API_AUTH}/up`;
  }, []);

  return useFetchDeferred(url);
}

export function useFetchProfilePermissions(profile, user) {
  const url = useMemo(() => {
    if (!profile || (!!user && user.userProfileId === profile.id)) {
      // user's permissions are loaded during login
      // when temporary profile is changed, new permissions are requested
      return null;
    }

    return `${API_AUTH}/up/${profile.id}/permissions`;
  }, [profile, user]);

  const handleFetchProfilePermissionsResponse = useCallback((response) => {
    return (!!response && response.permissions) || null;
  }, []);

  return useFetch(url, handleFetchProfilePermissionsResponse);
}

export function useFetchStoredToken(access_token, user) {
  const url = useMemo(() => {
    if (!access_token || !!user) {
      return null;
    }

    return `${API_TOKEN}/${access_token}`;
  }, [access_token, user]);

  return useFetch(url);
}