import React from 'react';
import styled from 'styled-components';

import { useStopPreventCallback } from '~/lib/Effects';
import { ProfileIconUrls } from '../../../../lib/Utils.Main';

const ProfileSelectorStyled = styled.div`
  border-radius: 10rem;
  background-color: var(--gprimary-desat-alt3);
  padding: 0.25rem;
  border: 1px solid var(--gsecondary-desat-alt2);
`;

const ProfileIconStyled = styled.img`
  border-radius: 50%;
  width: 2.5rem;
  opacity: 0.85;
`;

export default function ProfileSelector({
  user,
  profile,
  className = '',
  onProfileSelectOpen
}) {
  const handleProfileSelectOpen = useStopPreventCallback(onProfileSelectOpen);

  return (
    <React.Fragment>
      {!!profile && (
        <ProfileSelectorStyled
          className={`flex-row flex-nowrap align-items-center pointable ${className}`}
          onClick={handleProfileSelectOpen}
        >
          <ProfileIconStyled src={ProfileIconUrls[profile.id]} className="lift lift-g" />
          <div className="text-gprimary-alt2 text-uppercase font-weight-bold small mx-2">
            {profile.name}
          </div>
          <i className="fe fe-chevron-down text-gprimary-alt2" />
        </ProfileSelectorStyled>
      )}
    </React.Fragment>
  );
}