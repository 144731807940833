import React from 'react';
import DOMPurify from 'dompurify';

// Components
import { Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

export default function Cautions({
  cautions
}) {
  return (
    <Paragraph title="Cautions">
      <Text>{DOMPurify.sanitize(cautions || '')}</Text>
    </Paragraph>
  );
}