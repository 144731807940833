import React from 'react';
import styled from 'styled-components';
import DOMPurify from 'dompurify';

// Components
import Parallax from '~/components/parallax/Parallax';
import ProfileComponent, { ProfilePermissionType } from '~/components/profile/ProfileComponent';


const PageHeaderDescriptionStyled = styled.span`
  line-height: 1.3;
`;

export default function PageHeader({
  scope,
  title,
  img,
  children: description,
  imgShift
}) {
  return (
    <div className="flex-grow-0">
      <Parallax id="home-parallax" className="pt-5 pt-lg-8 pb-0" speed={0.6} imgSrc={img} imSize="cover" imgShift={imgShift}>
        <div className="row">
          <div className="col-12">

            <div className="p-5 bg-cover mx-0 mt-5 overlay overlay-dark overlay-60 shadow-dark">
              <div className="position-relative row py-3">
                {!!scope
                  ? (<React.Fragment>
                    <ProfileComponent name={`${scope}.title`}
                      required={[ProfilePermissionType.read]}
                      component={({ content }) => (
                        <h2
                          className="text-warning text-center font-weight-bold col-12 col-md-6 col-lg-4"
                          data-aos="fade-right"
                          data-aos-delay="100"
                        >
                          {content || title}
                        </h2>
                      )}
                    />
                    <ProfileComponent name={`${scope}.description`}
                      required={[ProfilePermissionType.read]}
                      component={({ content }) => (
                        <PageHeaderDescriptionStyled
                          className="small col-12 col-md-6 col-lg-8 text-white"
                          data-aos="fade-left"
                          data-aos-delay="100"
                        >
                          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content || description) }} />
                        </PageHeaderDescriptionStyled>
                      )}
                    />
                  </React.Fragment>
                  ) : (<React.Fragment>
                    <h2
                      className="text-warning text-center font-weight-bold col-12 col-md-6 col-lg-4"
                      data-aos="fade-right"
                      data-aos-delay="100">
                      {title}
                    </h2>
                    <PageHeaderDescriptionStyled
                      className="small col-12 col-md-6 col-lg-8 text-white"
                      data-aos="fade-left"
                      data-aos-delay="100"
                    >
                      <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
                    </PageHeaderDescriptionStyled>
                  </React.Fragment>)
                }

              </div>
            </div>

          </div>
        </div>
      </Parallax>
    </div>
  );
}