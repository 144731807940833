import React from 'react';
import styled from 'styled-components';

import { useProfileSelect, ProfileIconUrls, ProfileUrls } from '../../../../lib/Utils.Main';
import { useLocation } from 'react-router-dom';

// Components
import NavigationDropdownItem from './NavigationDropdownItem';
import NavigationItem from './NavigationItem';

export default function ProfileSelectMenu({
  profiles,
  className,
  currentProfile,
  onProfileSelect
}) {
  const { pathname } = useLocation();

  const getHandleProfileSelected = useProfileSelect(onProfileSelect);

  return (
    <NavigationDropdownItem label="profile" className={className}>
      {profiles.map((profile, indx) => {
        const selected = !!currentProfile && profile.id === currentProfile.id;
        const profileIconUrl = ProfileIconUrls[profile.id];
        const profileUrl = ProfileUrls[profile.id];

        return (
          <NavigationItem
            key={`profile-${profile.id}`}
            url={`/${profileUrl}/${pathname}`}
            label={
              <div
                className="d-flex flex-row justify-content-between"
                onClick={getHandleProfileSelected(profile)}
              >
                <div className="d-flex flex-row align-items-center mr-auto">
                  <div className="mr-5">
                    {!!profileIconUrl && (<img src={profileIconUrl} alt=".." width="32" />)}
                  </div>
                  <p className="font-weight-bold mb-1">
                    {profile.name}
                  </p>
                </div>
                <div>
                  {selected && (
                    <div className="badge badge-rounded-circle badge-gprimary ml-4">
                      <i className="fe fe-check"></i>
                    </div>
                  )}
                </div>
              </div>
            }
          />
        );
      })}

    </NavigationDropdownItem>
  );
}