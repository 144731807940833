import { useEffect, useCallback, useRef, useMemo, useState } from 'react';
import parseHeaderLink from 'parse-link-header';
import { useHistory } from 'react-router-dom';
import { createApi } from './Api';

import { makeCancelable } from './Utils';

import { useStore, actions } from '~/store/StoreProvider';
//import { usePrevious } from './Effects';

export function useCancellablePromise() {
  const promises = useRef([]);

  // useEffect initializes the promises array
  // and cleans up by calling cancel on every stored
  // promise.
  // Empty array as input to useEffect ensures that the hook is
  // called once during mount and the cancel() function called
  // once during unmount
  useEffect(
    () => {
      return function cancel() {
        promises.current.forEach(p => p.cancel());
        promises.current = [];
      };
    },
    []
  );

  return function cancellablePromise(p) {
    const cPromise = makeCancelable(p);

    promises.current.push(cPromise);

    return cPromise.promise;
  }
}

const DefaultOptions = ({
  method: 'GET'
});

function getUrlFromOptions(options) {
  return (options && `${options.path}${options.query || ''}`) || '';
}

function useFetchArguments(urlOrOptions /*string|object*/, responseCallbackOrOptions /*object|function*/, responseCallback /*function|undefined*/) {
  const url = useMemo(() => {
    return typeof urlOrOptions === 'string' ?
      urlOrOptions :
      getUrlFromOptions(urlOrOptions)
  }, [urlOrOptions]);

  const options = useMemo(() => {
    return typeof urlOrOptions === 'object' ?
      ({ ...DefaultOptions, ...urlOrOptions }) :
      typeof responseCallbackOrOptions === 'object' ?
        ({ ...DefaultOptions, ...responseCallbackOrOptions }) :
        DefaultOptions;
  }, [urlOrOptions, responseCallbackOrOptions]);

  const callback = useMemo(() => {
    return typeof responseCallbackOrOptions === 'function' ?
      responseCallbackOrOptions :
      typeof responseCallback === 'function' ?
        responseCallback :
        null;
  }, [responseCallbackOrOptions, responseCallback]);

  return { url, options, callback };
}

function useFetchAllArguments(urlOrOptions /*string|object|string[]|object[]*/, responseCallbackOrOptions /*object|object[]|function*/, responseCallback /*function|undefined*/) {
  const urls = useMemo(() => {
    const urlOrOptionsArray = Array.isArray(urlOrOptions) ? urlOrOptions : [urlOrOptions];

    return urlOrOptionsArray.every(uop => typeof uop === 'string') ?
      urlOrOptionsArray :
      urlOrOptionsArray.map(uop => getUrlFromOptions(uop))
  }, [urlOrOptions]);

  const options = useMemo(() => {
    const urlOrOptionsArray = Array.isArray(urlOrOptions) ? urlOrOptions : [urlOrOptions];
    const responseCallbackOrOptionsArray = Array.isArray(responseCallbackOrOptions) ? responseCallbackOrOptions : [responseCallbackOrOptions];

    return urlOrOptionsArray.every(uop => typeof uop === 'object') ?
      urlOrOptionsArray.map(o => ({ ...DefaultOptions, ...o })) :
      responseCallbackOrOptionsArray.every(rop => typeof rop === 'object') ?
        responseCallbackOrOptionsArray.map(o => ({ ...DefaultOptions, ...o })) :
        [DefaultOptions];
  }, [urlOrOptions, responseCallbackOrOptions]);

  const callback = useMemo(() => {
    return typeof responseCallbackOrOptions === 'function' ?
      responseCallbackOrOptions :
      typeof responseCallback === 'function' ?
        responseCallback :
        null;
  }, [responseCallbackOrOptions, responseCallback]);

  return { urls, options, callback };
}

const handleResponseError = error => {
  // it catches outer promise errors and transforms it into a usual response with ok = false, error, and no data
  if (error && error.isCanceled) {
    return null;
  }

  let errorData = null;
  let status = null;

  if (error.response) {
    errorData = error.response.data && (
      error.response.data.error /* api error message */ ||
      error.response.data.error_message /* django error message */) ||
      error.response.data.message/* auth error message */;
    status = error.response.status;
  } else if (error.request) {
    errorData = error.request.toString();
  } else {
    errorData = error.message;
  }

  return ({ error: errorData, status });
};

// is called after fetchData()
const handleResponseOk = ({ data, next, previous, config, headers }) => {
  const response = Array.isArray(data)
    ? [...data]
    : { ...data };

  response.config = config;

  const linkheader = parseHeaderLink(headers.link);

  return ({
    response,
    next: (next || (!!linkheader && !!linkheader.next && linkheader.next.url)),
    previous: (previous || (!!linkheader && !!linkheader.prev && linkheader.prev.url))
  });
};

// TODO: switch from response.count, and response.data.length, and query string start 
// to header links with first, prev, next, last
//
async function fetchData(api, url, options, cache = []) {
  const optionsWithUrl = {
    ...options,
    url
  };

  return new Promise((resolve, reject) => {
    api
      .request(optionsWithUrl, { withCredentials: true, crossDomain: true })
      .then(response => {
        const uri = new URL(`${api.defaults.baseURL}${url}`);
        const searchParams = uri.searchParams;
        const maybeMultiPageResponse = !!response.data && !!response.data.meta && !!response.data.objects;  // if data.meta presents, all pages auto load can be used
        const explicitMultiPageRequest = searchParams.has('offset') || searchParams.has('limit');

        if (!maybeMultiPageResponse) {
          resolve(response);
        } else if (!explicitMultiPageRequest || cache.length > 0) {
          const objects = cache.concat(response.data.objects);

          if (response.data.meta.totalCount > objects.length) {
            const newUrl = `${uri.origin}${response.data.meta.next}`;

            fetchData(api, newUrl, optionsWithUrl, objects)
              .then(resolve)
              .catch(reject);
          } else {
            // we keep all original fields of api.fetch.response.data ({count, [data], ...}), but [data] is concatenated
            resolve({
              ...response,
              data: { ...response.data /*count, [data], ...*/, objects /*concatenaded*/ }
            });
          }
        } else {
          // this is probably an explicit paginated request with start and/or rows parameters
          resolve(response);
        }
      })
      .catch(error => reject(error));
  });
}

function useFetchInfrastructure(url, options, callback) {
  const [fetchUrlOptionsCallback, setFetchUrlOptionsCallback] = useState();
  const history = useHistory();
  const [responseObject, setResponseObject] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [nextUrl, setNextUrl] = useState(null);
  const [prevUrl, setPrevUrl] = useState(null);
  const [reloadTrigger, setReloadTrigger] = useState({});
  const joinMode = useRef(false);

  useEffect(() => {
    setFetchUrlOptionsCallback({ url, options, callback });
  }, [url, options, callback]);

  const api = useMemo(() => createApi(history), [history]);

  const promise = useRef(useCancellablePromise());

  const reload = useCallback(() => {
    // when useFetch (and an upper hook) has no logical dependencies that may cause data reloading,
    // this method can be usefull.
    // this is a little bit imperative, but is better than to add artificial dependencies
    // to avoid dowble triggering, either url or reloadTrigger must be set
    setFetchUrlOptionsCallback({ url, options, callback });

    joinMode.current = false;
    setNextUrl(null);
    setPrevUrl(null);

    setReloadTrigger({});
  }, [url, options, callback]);

  const next = useCallback((merge) => {
    if (!!nextUrl) {
      joinMode.current = merge;
      //setLoading(true);
      setFetchUrlOptionsCallback({ url: nextUrl, options, callback });
      //setReloadTrigger({}); // it reloads because of different url
    }
  }, [nextUrl, options, callback]);

  const previous = useCallback(() => {
    if (!!prevUrl) {
      setFetchUrlOptionsCallback({ url: prevUrl, options, callback });
      //setReloadTrigger({}); // it reloads because of different url
    }
  }, [prevUrl, options, callback]);

  const setResponse = useCallback((response, next, prev) => {
    setResponseObject(res => !!joinMode.current
      ? ({
        ...res,
        data: res.data.concat(response.data),
        rows: !!res.rows
          ? !!response.rows
            ? res.rows + response.rows
            : res.rows
          : !!response.rows
            ? response.rows
            : 0
      })
      : response
    );

    setNextUrl(next);
    setPrevUrl(prev);

    //setLoading(false);

    joinMode.current = false;
  }, []);

  return ({
    response: responseObject, setResponse,
    loading, setLoading,
    error, setError,
    reload, reloadTrigger,
    next, previous,
    api,
    fetchUrlOptionsCallback,
    promise: promise.current
  });
}


// call it like useFetch('site.com', callback) or useFetch('site.com', {options}, callback), or useFeth({path:'site.com'}, callback)
export function useFetch(...args) {
  const { dispatch } = useStore();

  const { url, options, callback } = useFetchArguments(...args);

  const {
    response, setResponse,
    loading, setLoading,
    error, setError,
    reload, reloadTrigger,
    next, previous,
    api,
    fetchUrlOptionsCallback,
    promise
  } = useFetchInfrastructure(url, options, callback);

  useEffect(() => {
    if (!!fetchUrlOptionsCallback) {
      if (!!fetchUrlOptionsCallback.url) {
        setLoading(true);

        dispatch(actions.loading.start());

        (async () => {
          const result = await promise(fetchData(api, fetchUrlOptionsCallback.url, fetchUrlOptionsCallback.options))
            .then(handleResponseOk)
            .catch(handleResponseError); // a request might be cancelled by unmount

          dispatch(actions.loading.stop());

          if (!!result) {
            setLoading(false);
            setError(result.error);

            setResponse(fetchUrlOptionsCallback.callback
              ? fetchUrlOptionsCallback.callback(result.response)
              : result.response, result.next, result.previous
            );
          }
        })();
      } else {
        setError(null);

        setResponse(fetchUrlOptionsCallback.callback
          ? fetchUrlOptionsCallback.callback(null)
          : null
        );
      }
    }
  }, [fetchUrlOptionsCallback, dispatch, reloadTrigger, api, promise, setError, setLoading, setResponse]);

  return [{ response, loading, error }, { reload, next, previous }];
}

export function useFetchAll(...args) {
  const { dispatch } = useStore();

  const { urls, options, callback } = useFetchAllArguments(...args);

  const {
    response, setResponse,
    loading, setLoading,
    error, setError,
    reload, reloadTrigger,
    api,
    promise
  } = useFetchInfrastructure();

  useEffect(() => {
    if (!!urls && urls.length > 0 && urls.some(url => !!url)) {
      setLoading(true);

      dispatch(actions.loading.start());

      (async () => {
        const results = await Promise.all(
          urls.filter(url => !!url).map((url, indx) =>
            promise(fetchData(api, url, options[indx]))
              .then(handleResponseOk)
              .catch(handleResponseError) // a request might be cancelled by unmount
          )
        );

        dispatch(actions.loading.stop());

        if (!!results && results.length === urls.length && results.every(r => !!r /* was not cancelled */)) {
          setLoading(false);
          setError(results.map(({ error }) => error));
          setResponse(results.map(({ response }) => callback ? callback(response) : response));
        }

      })();
    } else {
      setError(null);
      setResponse([]);
    }
  }, [urls, options, callback, dispatch, reloadTrigger, api, promise, setError, setResponse, setLoading]);

  return [{ response, loading, error }, { reload }];
}

export function useFetchDeferred(...args) {
  const { dispatch } = useStore();
  const { url, options } = useFetchArguments(...args);

  const { api } = useFetchInfrastructure();

  const promise = useRef(useCancellablePromise());

  return useCallback(
    async (volatileOptions = {}) => {
      const fullOptions = ({ ...options, ...volatileOptions });
      const path = `${fullOptions.path || url}${fullOptions.query || ''}`;

      if (path) {
        dispatch(actions.loading.start());

        const result = await promise.current(fetchData(api, path, fullOptions))
          .then(handleResponseOk)
          .catch(handleResponseError); // a request might be cancelled by unmount

        dispatch(actions.loading.stop());

        return result;
      } else {
        return Promise.resolve(null);
      }
    },
    [url, options, dispatch, api]
  );
}

/**
 * debug helper
 *
  const prevFetchUrl = usePrevious(fetchUrl);
  const prevOptions = usePrevious(options);
  const prevCallback = usePrevious(callback);
  const prevDispath = usePrevious(dispatch);
  const prevReloadTrigger = usePrevious(reloadTrigger);
  const prevApi = usePrevious(api);
  const prevPromise = usePrevious(promise);
  const prevSetError = usePrevious(setError);
  const prevSetLoading = usePrevious(setLoading);
  const prevSetResponse = usePrevious(setResponse);

  console.log(fetchUrl, prevFetchUrl !== fetchUrl, prevOptions !== options, prevCallback !== callback, prevDispath !== dispatch,
    prevReloadTrigger !== reloadTrigger, prevApi !== api, prevPromise !== promise, prevSetError !== setError, prevSetLoading !== setLoading,
    prevSetResponse !== setResponse
  );
 */