import React, { forwardRef } from 'react';

// Components
import { NavLink } from 'react-router-dom';

export default forwardRef(function InternalExternalNavLink({
  to,
  children,
  className = '',
  ...props
}, externalRef) {
  const propsClean = ({ ...props });
  delete propsClean.url;
  delete propsClean.content;
  delete propsClean.iframe;

  return (
    <React.Fragment>
      {!!to && to.startsWith('/')
        ? (
          <NavLink
            {...props}
            className={`nav-link ${className}`}
            exact={true}
            to={to}
            ref={externalRef}
          >
            {children}
          </NavLink>
        ) : (
          <a
            {...props}
            className={`nav-link ${className}`}
            href={to}
            target="_blank"
            rel="noopener noreferrer"
            ref={externalRef}
          >
            {children}
          </a>
        )}
    </React.Fragment>
  )
});