import React from 'react';
import styled from 'styled-components';

// Components
import UserAvatar from './MainMenu/UserAvatar';
import UserSignIn from './MainMenu/UserSignIn';
import NavigationDropdownItem from './MainMenu/NavigationDropdownItem';
import NavigationItem from './MainMenu/NavigationItem';
import Toggler from './MainMenu/Toggler';
import Crowdsourcing from './MainMenu/Crowdsourcing';
import CrowdsourcingReview from './MainMenu/CrowdsourcingReview';
import ProfileComponent, { ProfilePermissionType } from '~/components/profile/ProfileComponent';
import ProfileSelector from './MainMenu/ProfileSelector';
import ProfileSelectMenu from './MainMenu/ProfileSelectMenu';

const NavbarStyled = styled.ul`
  & > .nav-item {
    padding-top: 0.5rem;
  }

  .nav-link {
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    color: var(--gprimary) !important;

    font-size: 0.9rem !important;
  }

  .nav-link:hover, .nav-link.active {
    color: var(--gsuccess-active) !important;
  }

  .dropdown-menu {
    border: 1px solid var(--gsecondary-desat-alt2);
    
    padding-left: 0 !important;
    padding-right: 0 !important;

    .nav-link {
      padding-left: 2.75rem !important;
      padding-right: 2.75rem !important;
    }

    .nav-link.active {
      background-color: var(--gprimary-desat-alt3);
    }

    .dropdown-header {
      margin-left: 0 !important;
    }
  }
`;

function Navigation({
  user,
  profile,
  profiles,
  onProfileSelect,
  onProfileSelectOpen
}) {

  return (
    <React.Fragment>
      <NavbarStyled className="navbar-nav ml-auto">
        {/*<ProfileComponent name="home.navbar.home" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
          <NavigationItem url="/home" label="Home" />
  </ProfileComponent>*/}

        <ProfileComponent name="home.navbar.about" required={[ProfilePermissionType.read]}>
          <NavigationDropdownItem label="About">

            <ProfileComponent name="home.navbar.about.about_geoikp" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/about" label="About this platform" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.about.about_project" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/about" label="OPERANDUM project" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.help" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/help" label="Help" />
            </ProfileComponent>

          </NavigationDropdownItem>
        </ProfileComponent>

        <ProfileComponent name="home.navbar.nbs" required={[ProfilePermissionType.read]}>
          <NavigationDropdownItem label="NBS">

            <ProfileComponent name="home.navbar.nbs.about" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/nbs/lifecycle" label="NBS Lifecycle" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.nbs.explorer" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/nbs/explorer" label="NBS Explorer" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.nbs.oal_explorer" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/oal/explorer" label="OAL Explorer" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.nbs.toolkit" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/data/toolkit" label="NBS Toolkit" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.nbs.citizen_stories" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/data/story" label="Citizen stories" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.nbs.crowdsourcing" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <Crowdsourcing url="http://crowd-geoikp.kajoservices.com//views/map" label="Add your NBS project" user={user} profile={profile} />
            </ProfileComponent>

          </NavigationDropdownItem>
        </ProfileComponent>

        <ProfileComponent name="home.navbar.citizen_stories" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
          <NavigationItem url="/data/story" label="Citizens' stories" />
        </ProfileComponent>

        <ProfileComponent name="home.navbar.toolkit" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
          <NavigationItem url="/data/toolkit" label="NBS Toolkit" />
        </ProfileComponent>

        <ProfileComponent name="home.navbar.map" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
          <NavigationItem url="/data/map" label="Interactive map" />
        </ProfileComponent>

        <ProfileComponent name="home.navbar.policy_catalogue" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
          <NavigationItem url="/policy/catalogue" label="Policy catalogue" />
        </ProfileComponent>

        <ProfileComponent name="home.navbar.oal" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
          <NavigationItem url="/oal/explorer" label="OAL" />
        </ProfileComponent>

        <ProfileComponent name="home.navbar.data" required={[ProfilePermissionType.read]}>
          <NavigationDropdownItem label="Data &amp; Tools">

            <ProfileComponent name="home.navbar.data.data" required={[ProfilePermissionType.read]}>
              <h6 className="dropdown-header">Data</h6>
            </ProfileComponent>

            <ProfileComponent name="home.navbar.data.business_network" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/data/networking" label="Business networking" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.data.map" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/data/map" label="Map view" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.data.ckan" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="http://operandum_ds.kajoservices.com/" label="Metadata" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.data.crowdsourcing" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <Crowdsourcing url="http://crowd-geoikp.kajoservices.com//views/map" label="NBS Crowdsourcing" user={user} profile={profile} />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.data.tools" required={[ProfilePermissionType.read]}>
              <h6 className="dropdown-header mt-2">Tools</h6>
            </ProfileComponent>

            <ProfileComponent name="home.navbar.data.toolkit" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/data/toolkit" label="NBS Toolkit" />
            </ProfileComponent>

          </NavigationDropdownItem>
        </ProfileComponent>

        <ProfileComponent name="home.navbar.policy" required={[ProfilePermissionType.read]}>
          <NavigationDropdownItem label="Policy">

            <ProfileComponent name="home.navbar.policy.catalogue" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/policy/catalogue" label="Policy catalogue" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.policy.permitting_path" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/policy/path" label="Policy permitting paths" />
            </ProfileComponent>

          </NavigationDropdownItem>
        </ProfileComponent>

        <ProfileComponent name="home.navbar.resources" required={[ProfilePermissionType.read]}>
          <NavigationDropdownItem label="Resources">

            <ProfileComponent name="home.navbar.resources.data.map" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/data/map" label="Interactive map" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.resources.policy.catalogue" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/policy/catalogue" label="Policy catalogue" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.resources.permitting_path" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/policy/path" label="Policy permitting paths" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.resources.data.ckan" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="http://operandum_ds.kajoservices.com/" label="Data Catalogue" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.resources.data.crowdsourcing" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <Crowdsourcing url="http://crowd-geoikp.kajoservices.com/views/map" label="Add your NBS" user={user} profile={profile} />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.resources.tools.toolkit" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/data/toolkit" label="NBS Toolkit" />
            </ProfileComponent>

          </NavigationDropdownItem>
        </ProfileComponent>

        { /* Associations */}
        <ProfileComponent name="home.navbar.design" required={[ProfilePermissionType.read]}>
          <NavigationDropdownItem label="Design your Solution">
            <ProfileComponent name="home.navbar.design.citizen_stories" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/data/story" label="Citizens' stories" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.design.toolkit" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/data/toolkit" label="NBS Toolkit" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.design.map" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/data/map" label="Interactive map" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.design.permitting_path" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/policy/path" label="Policy permitting paths" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.design.policy_catalogue" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/policy/catalogue" label="Policy catalogue" />
            </ProfileComponent>
          </NavigationDropdownItem>
        </ProfileComponent>

        <ProfileComponent name="home.navbar.contribute" required={[ProfilePermissionType.read]}>
          <NavigationDropdownItem label="Contribute">
            <ProfileComponent name="home.navbar.contribute.crowdsourcing" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <Crowdsourcing url="/nbs/crowdsourcing" label="Add your NBS project" user={user} profile={profile} />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.contribute.story" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <Crowdsourcing url="/data/story/add" label="Add your NBS project" user={user} profile={profile} />
            </ProfileComponent></NavigationDropdownItem>
        </ProfileComponent>

        {/* completely removed after permissions are introduced */}
        {/* <ProfileComponent name="home.navbar.project" required={[ProfilePermissionType.read]}>
          <NavigationDropdownItem label="OPERANDUM Project">

            <ProfileComponent name="home.navbar.about_geoikp" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="/about" label="About" />
            </ProfileComponent>

            <ProfileComponent name="home.navbar.about_project" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
              <NavigationItem url="https://www.operandum-project.eu/" label="OPERANDUM project" />
            </ProfileComponent>

            <NavigationItem url="/partner" label="Partners" />
            <NavigationItem url="/contact" label="Contact" />
          </NavigationDropdownItem>
        </ProfileComponent> */}

        {!user && (
          <React.Fragment>
            <ProfileSelector
              className="d-none d-lg-flex"
              user={user}
              profile={profile}
              onProfileSelectOpen={onProfileSelectOpen}
            />
            <ProfileSelectMenu
              className="d-list-item d-lg-none"
              profiles={profiles}
              currentProfile={profile}
              onProfileSelect={onProfileSelect}
            />
          </React.Fragment>
        )}


        <ProfileComponent name="home.navbar.account.signin" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
          {!user
            ? <UserSignIn />
            : <UserAvatar
              user={user}
              badge={<ProfileComponent name="nbs_explorer.review_nbs" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
                <CrowdsourcingReview
                  url="http://nbs.create-support.online/App/Nbs/Review"
                  label="Review NBS"
                  user={user}
                  profile={profile}
                />
              </ProfileComponent>}
            />
          }
        </ProfileComponent>

        {/*<Link to="/help" className="ml-5">
        <button className="w-100 btn btn-sm btn-outline-primary lift mb-2 mb-lg-0">
          Help
        </button>
      </Link>*/}
      </NavbarStyled>
    </React.Fragment>
  );
}

export default function MainMenu({
  user,
  profiles,
  currentProfile,
  onProfileSelect,
  onProfileSelectOpen
}) {
  return (
    <div className="collapse navbar-collapse pr-8 pr-lg-0" id="navbarCollapse">
      <Toggler />
      <Navigation
        user={user}
        profile={currentProfile}
        profiles={profiles}
        onProfileSelect={onProfileSelect}
        onProfileSelectOpen={onProfileSelectOpen}
      />
    </div>
  );
}
