import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Owa } from '~/owa/OpenWebAnalyticsProvider';

// Components
import PermissionedView from './PermissionedView';

export default function ModalPanelView({
  children
}) {

  const tracker = useContext(Owa);
  const location = useLocation();

  useEffect(() => {
    if (!!tracker && !!location) {
      tracker.trackPageView(location.pathname);
    }
  }, [location, tracker]);


  return (
    <PermissionedView>
      {children}
    </PermissionedView>
  );
}