import React, { forwardRef, useCallback, useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import { is } from '~/lib/Utils';

const HeaderStyled = styled.div`
  ${({ riseHeader }) => riseHeader ? 'box-shadow: 4px 3px 6px #00000029' : ''};

  border-bottom: none !important;
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;

  cursor: default;

  z-index: 2;
`;

const ContentStyled = styled.div`
  background-color: transparent !important;
  border-radius: 0.75rem !important;
`;

const BodyStyled = styled.div`
  background-color: transparent;
  border-bottom-left-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
`;

const FooterStyled = styled.div`
  background-color: transparent;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: none !important;
`;

const modalRoot = document.getElementById('modal-root');

function cleanupModalRoot(id, element) {
  const modalDrops = document.getElementsByClassName('modal-backdrop');

  if (!!modalDrops.length) {
    for (let item of modalDrops) {
      item.remove();
    }
  }


  modalRoot.childNodes.forEach(child => {
    if (child === element || !child.hasChildNodes() || (!!child.firstChild && child.firstChild.id === id)) {
      modalRoot.removeChild(child);
    }
  });

  if (!!element) {
    modalRoot.appendChild(element);
  }
}

function showModal(id, show, element, onClose) {
  cleanupModalRoot(id, show && element);

  window.jQuery(`#${id}`).modal(show ? 'show' : 'hide');

  if (show) {
    window.jQuery(`#${id}`).on('hidden.bs.modal', onClose);
  } else {
    window.jQuery(`#${id}`).off('hidden.bs.modal', onClose);
  }
}

export default forwardRef(function Modal(
  {
    id = 'modal',
    title = 'Modal',
    footer = null,
    children,
    show = false,
    backdrop = true,
    size = 'xl',
    riseHeader = true,
    headerClassName = 'bg-gwhite text-secondary',
    bodyClassName = '',
    footerClassName = '',
    onClose },
  ref) {

  const handleOnClose = useCallback(() => {
    if (is.func(onClose)) {
      onClose();  // it must change show externally
    } else {
      // default implementation
      showModal(id, false);
    }
  }, [id, onClose]);

  const element = useMemo(() => document.createElement('div'), []);

  const handleInternalClose = useCallback(() => {
    cleanupModalRoot(id);

    is.func(onClose) && onClose();
  }, [id, onClose]);

  useEffect(() => {
    showModal(id, show, element, handleInternalClose);
  }, [show, element, id, handleInternalClose]);

  return createPortal(
    (
      <div
        ref={ref}
        className="modal fade"
        id={id}
        tabIndex="-1"
        role="dialog"
        aria-labelledby={`${id}-title`}
        aria-hidden="true"
        data-backdrop={backdrop}
      >
        <div
          className={`modal-dialog modal-dialog-centered modal-${size}`}
          role="document"
        >
          <ContentStyled className="modal-content">
            <HeaderStyled className={`modal-header ${headerClassName}`} riseHeader={riseHeader}>
              <h5
                className="modal-title text-center w-100 font-weight-bold"
                id={`${id}-title`}
              >
                {title}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={handleOnClose}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </HeaderStyled>

            <BodyStyled className={`modal-body ${bodyClassName}`}>
              {children}
            </BodyStyled>

            {!!footer && (
              <FooterStyled className={`modal-footer ${footerClassName}`}>
                {footer}
              </FooterStyled>
            )}
          </ContentStyled>
        </div>
      </div>
    ),
    element);
});