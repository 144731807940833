import React from 'react';

// Components
import { Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

export default function A({
  costs
}) {
  return (
    <Paragraph title="Costs">
      <Text>{costs}</Text>
    </Paragraph>
  );
}