import hash from 'object-hash';
import { camelCase, snakeCase, pascalCase, sentenceCase } from 'change-case';
import { titleCase } from 'title-case';
import { format as formatDate, parse as parseDate } from 'date-fns';
import formatString from 'string-placeholder';
import { sizeFormatter } from 'human-readable';
import pointInPolygon from 'point-in-polygon';

import L from 'leaflet';
import Draw from 'react-leaflet-draw';  // exports L.GeometryUtils.formattedNumber

export { formatDate, parseDate };

export { formatString };

export const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
];

/*eslint-disable no-extend-native*/
if (!Array.prototype.count) {
  Array.prototype.count = function (predicate = null) {
    return this.length <= 0
      ? 0
      : !!predicate
        ? this.reduce((tot, i, indx) => tot + Number(predicate(i, indx) || false), 0)
        : this.length;
  };
};

if (!Array.prototype.last) {
  Array.prototype.last = function (predicate = null) {
    return this.length <= 0
      ? undefined
      : !!predicate
        ? this.reduce((tot, i, indx) => predicate(i, indx) || tot, undefined)
        : this[this.length - 1];
  };
};

if (!Array.prototype.first) {
  Array.prototype.first = function (predicate = null) {
    return this.length <= 0
      ? undefined
      : !!predicate
        ? this.reduce((tot, i, indx) => tot || (predicate(i, indx) ? i : tot), undefined)
        : this[0];
  };
};

if (!Array.prototype.sum) {
  Array.prototype.sum = function (predicate = null) {
    return this.length <= 0
      ? undefined
      : this.reduce((tot, i, indx) => tot + (!!predicate ? predicate(i, indx) : i), 0);
  };
};

if (!Array.prototype.take) {
  Array.prototype.take = function (num) {
    return this.length <= 0 || this.length <= num
      ? [...this]
      : this.filter((_i, indx) => indx < num);
  }
}

if (!Array.prototype.takeRight) {
  Array.prototype.takeRight = function (num) {
    return this.length <= 0 || this.length <= num
      ? [...this]
      : this.filter((_i, indx, arr) => indx >= arr.length - num);
  }
}

if (!Array.prototype.skip) {
  Array.prototype.skip = function (num) {
    return this.length <= 0 || this.length <= num
      ? []
      : this.filter((_i, indx) => indx >= num);
  }
}

if (!Array.prototype.removeAll) {
  Array.prototype.removeAll = function () {
    return this.splice(0, this.length);
  }
}

if (!Array.prototype.min) {
  Array.prototype.min = function (predicate = null) {
    return this.length <= 0
      ? undefined
      : !!predicate
        ? this.reduce((res, i) => res || (!res ? i : predicate(i, res) ? i : res), undefined)
        : this[0];
  }
}

if (!Array.prototype.flaten) {
  Array.prototype.flaten = function () {
    return this.length <= 0
      ? []
      : this.reduce((res, i) => res.concat(Array.isArray(i) ? i.flaten() : i), []);
  }
}

if (!Array.prototype.spawn) {
  Array.prototype.spawn = function (num = 0, val) {
    return num <= 0
      ? []
      : this.concat(Array.apply(null, Array(num)).map((_, i) => Number(val || i)));
  }
}

if (!Array.prototype.shuffle) {
  Array.prototype.shuffle = function () {
    const array = [...this];
    let currentIndex = array.length;

    // While there remain elements to shuffle.
    while (currentIndex !== 0) {

      // Pick a remaining element.
      const randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }
}
/*eslint-enable no-extend-native*/


export function capitalizeStr(str) {
  if (!str) {
    return str;
  }

  return str.replace(/^\w/, (c) => c.toUpperCase());
}

export function openUrlNoOpener(url, target = "_blank") {
  const a = document.createElement("a");
  a.href = url;
  a.target = target;
  a.rel = "noopener noreferrer";
  a.click();
}

export function makeCancelable(promise) {
  let isCanceled = false;
  const wrappedPromise =
    new Promise((resolve, reject) => {
      promise
        .then(
          val => (isCanceled
            ? reject({ isCanceled })
            : resolve(val))
        )
        .catch(
          error => (isCanceled
            ? reject({ isCanceled })
            : reject(error))
        );
    });
  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
}

export function sortNumbers(a, b) {
  return Number(a) > Number(b) ? 1 : Number(a) < Number(b) ? -1 : 0;
}

export function noop() { }

export function createElement(element, classNames = '', parent = null) {
  const el = document.createElement(element);
  el.className = classNames;

  if (parent) {
    parent.appendChild(el);

    return [el, () => parent.removeChild(el)];
  }

  return el;
}

export function addClassName(element, className) {
  if (element && !element.classList.contains(className)) {
    element.classList.add(className);
  }
}

export function removeClassName(element, className) {
  if (element && element.classList.contains(className)) {
    element.classList.remove(className);
  }
}

export function encodeElementId(value) {
  return value && value.replace(/[^0-9A-Za-z\-_]/gi, "-");
}

export function stopPreventEvent(e) {
  e.preventDefault();
  e.stopPropagation();
}

export function eventTargetBlur(e) {
  !!e && !!e.currentTarget && is.func(e.currentTarget.blur) && e.currentTarget.blur();
}

export const is = {
  number: (val) => val !== null && val !== undefined && !isNaN(Number(val)),
  string: (val) => val !== null && typeof val === "string",
  array: (val) => val !== null && Array.isArray(val),
  notUndef: (val) => val !== null && val !== undefined,
  undef: (val) => val === null || val === undefined,
  func: (val) => val !== null && val !== undefined && typeof val === 'function',
  object: (val) => val !== null && val !== undefined && typeof val === 'object',
  nullOrEmptyString: (val) => !val || !val.trim(),
  empty: (val, check) => is.undef(val)
    ? true
    : is.notUndef(val.length)
      ? val.length === 0
      : !is.object(val)
        ? false
        : Object.keys(val).length === 0 || Object
          .keys(val)
          .map(key => {
            let emp = is.func(check) ? check(val, key) : undefined;

            return is.undef(emp) ? is.empty(val[key]) /* no check or didn't checked */ : emp /* true or false */
          })
          .every(e => e),
  absoluteUrl: (val) => !!val && (val.includes('://') || val.startsWith('//'))
};

export async function downloadFile(file, callback) {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject();
    }

    const reader = new FileReader();
    reader.readAsText(file.slice(0, 10 * 1024 * 1024)); // a protection against huge files

    reader.onload = ({ target: { result } }) =>
      resolve(is.func(callback) ? callback(result) : result);
    reader.onerror = () => reject("read file error");
  });
}

export function convertArrayOfObjectsToCSV(data, columnDelimiter = ',', lineDelimiter = '\n') {
  if (data === null || !data.length) {
    return null;
  }

  const keys = Object.keys(data[0]);

  let result = '';
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach(function (item) {
    let ctr = 0;
    keys.forEach(function (key) {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];
      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

export function downloadCSV(data, fileName = 'export.csv') {
  let csv = convertArrayOfObjectsToCSV(data);

  if (csv === null) {
    return;
  }

  if (!csv.match(/^data:text\/csv/i)) {
    csv = 'data:text/csv;charset=utf-8,' + csv;
  }

  data = encodeURI(csv);

  const link = document.createElement('a');
  link.setAttribute('href', data);
  link.setAttribute('download', fileName);
  link.click();
}

export const formatFileSize = sizeFormatter({
  std: 'SI', // 'SI' (default) | 'IEC' | 'JEDEC'
  decimalPlaces: 2,
  keepTrailingZeroes: false,
  render: (literal, symbol) => `${literal} ${symbol}B`,
});

export function objectHash(obj, pure = false) {
  const hashFn = !!obj && (!obj.length || obj.length < (1024 * 1024))
    ? hash // not null, not a string or a short string
    : (obj) => !!obj ? obj.length : '0';

  return pure
    ? hash(obj)
    : hashFn(obj);
}

export async function copyToClipboard(value, parent) {
  return new Promise((resolve, reject) => {
    const [textArea, removeTextArea] = createElement(
      "textarea",
      "",
      parent || document.body
      // bottstrap modals grabs focus from all elements outside of a dialog, and this prevents text area value to be copied
      // this is why parent, which must be a part of the dialog, have to be provided
    );
    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.style.zIndex = 99999;

    textArea.value = `${value}`;

    try {
      textArea.focus();
      textArea.select();
      textArea.setSelectionRange(0, 500);
      textArea.blur();

      document.execCommand("copy");

      removeTextArea();

      // 1: here some async stuff might apper when clipboard API changes
      // 2: allows to easy separate successfull pocying and failed one

      resolve(value);
    } catch (ex) {
      reject(ex);
    }
  });
}


function _objectToCase(obj, convertFn) {
  if (is.undef(obj) || is.undef(convertFn) || !is.func(convertFn)) {
    return obj;
  } else if (is.array(obj)) {
    return obj.map(o => _objectToCase(o, convertFn));
  } else if (!is.object(obj)) {
    return (obj);
  }

  let result = {};

  Object.keys(obj).forEach(key => {
    result[convertFn(key)] = is.array(obj[key])
      ? obj[key].map(o => _objectToCase(o, convertFn))
      : is.object(obj[key])
        ? _objectToCase(obj[key], convertFn)
        : obj[key];
  });

  return result;
}

export function objectToCamelCase(obj) {
  return _objectToCase(obj, camelCase);
}

export function objectToSnakeCase(obj) {
  return _objectToCase(obj, snakeCase)
}

export function objectToPascalCase(obj) {
  return _objectToCase(obj, pascalCase);
}

function _stringToCase(value, convertFn) {
  if (is.undef(value) || is.undef(convertFn) || !is.func(convertFn)) {
    return value;
  } else if (is.array(value)) {
    return value.map(o => _stringToCase(o, convertFn));
  } else if (is.object(value)) {
    return (value);
  }

  return convertFn(value);
}

export function stringToSentenseCase(str) {
  return _stringToCase(str, sentenceCase);
}

export function stringToTitleCase(str) {
  return _stringToCase(str, titleCase);
}

export function stringToSnakeCase(str) {
  return _stringToCase(str, snakeCase);
}

export function getRandomIdString() {
  return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
}

export function scrollOnTopAnimated(element, { duration = 500, callback, top = 0 } = {}) {
  if (!!element) {
    window.jQuery(element === window
      ? document.documentElement
      : element)
      .stop()
      .animate({ scrollTop: top }, duration, 'swing', callback);
  }
}

export function scrollOnePageAnimated({ direction = 'down', duration = 500, callback, pageScrollPercentage = 100 }) {
  const pageHeight = window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight
    || 0;

  const scrollY = Math.ceil(pageHeight * pageScrollPercentage / 100) * (direction === 'down' ? 1 : -1);

  window.jQuery(document.documentElement)
    .stop()
    .animate({ scrollTop: window.scrollY + scrollY }, duration, 'swing', callback);
}

export function downloadURI(uri, name) {
  const link = document.createElement('a');
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);
}

export function isPointInsidePolygon(point, polygon) {
  if (!point || !polygon) {
    return undefined;
  }

  const p = Array.isArray(point) ? point : [point.x, point.y];

  return pointInPolygon(p, polygon);
}

export function cloneObject(obj) {
  if (!obj) {
    return obj;
  } else if (!is.object(obj)) {
    return obj;
  } else {
    const result = ({});

    Object.keys(obj).forEach(key => {
      const o = obj[key];

      if (!is.object(o)) {
        result[key] = o;
      } else if (is.array(o)) {
        result[key] = o.map(oi => cloneObject(oi));
      } else {
        result[key] = cloneObject(o);
      }
    })

    return result;
  }
}

export function formatArea(area) {
  if (area >= 1000000) {
    return L.GeometryUtil.formattedNumber(area * 0.000001, 1) + ' km²';
  } else if (area >= 10000) {
    return L.GeometryUtil.formattedNumber(area * 0.0001, 1) + ' ha';
  } else {
    return L.GeometryUtil.formattedNumber(area, 0) + ' m²';
  }
}
