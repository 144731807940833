import React, { forwardRef } from 'react';
import styled from 'styled-components';

// Components
import { Link } from 'react-router-dom';
import ProfileComponent, { ProfilePermissionType } from '~/components/profile/ProfileComponent';

const DropdownSplitStyled = styled.button`
  &:after {
    display: inherit !important;
  }
`;

const SignupDropdownStyled = styled.div`
  left: 0 !important;
`;

export default forwardRef(function UserSignIn(_, externalRef) {
  return (
    <React.Fragment>
      <div
        className="ml-5 btn-group dropdown d-none d-lg-inline-flex"
        ref={externalRef}
      >
        <Link
          to="/login"
          role="button"
          className="w-100 btn btn-sm btn-gprimary btn-pill mb-2 mb-lg-0 text-nowrap d-flex flex-row align-items-center"
        >
          Sign in
        </Link>

        <ProfileComponent name="home.navbar.account.signup" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
          <DropdownSplitStyled
            id="signin"
            className="btn btn-gprimary btn-pill dropdown-toggle dropdown-toggle-split mb-2 mb-lg-0 py-2"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="sr-only">Toggle Dropdown</span>
          </DropdownSplitStyled>

          <SignupDropdownStyled className="dropdown-menu shadow-dark  bg-gprimary-desat-alt2" aria-labelledby="signin">
            <div className="list-group list-group-flush mx-5 bg-gprimary-desat-alt2">
              <div className="list-group-item d-flex font-weight-normal align-items-center  bg-gprimary-desat-alt2">
                <div className="icon icon-sm text-primary">
                  <img src="/assets/img/icons/duotone-icons/Communication/Add-user.svg" alt="..." width="36" />
                </div>
                <div className="ml-4">
                  <h6 className="font-weight-bold text-uppercase text-gprimary mb-0">
                    Sign up
                  </h6>
                  <p className="font-size-sm text-gray-700 mb-0">
                    <span className="small">Do you want access to advanced features, tools, and data?</span>
                    <Link to="/signup" role="button" className="btn btn-sm btn-gwarning-desat mt-2 lift lift-g">Register</Link>
                  </p>
                </div>
              </div>
            </div>
          </SignupDropdownStyled>
        </ProfileComponent>
      </div>

      <div className="d-flex d-lg-none flex-row justyfy-content-between p-5">
        <Link
          to="/login"
          role="button"
          className="w-100 btn btn-sm btn-gprimary btn-pill mb-2 mb-lg-0 mr-5 text-nowrap lift lift-g"
        >
          Sign in
        </Link>

        <ProfileComponent name="home.navbar.account.signup" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
          <Link
            to="/signup"
            role="button"
            className="w-100 btn btn-sm btn-gsuccess-dark btn-pill mb-2 mb-lg-0 text-nowrap text-gwhite lift lift-g"
          >
            Sign up
          </Link>
        </ProfileComponent>
      </div>
    </React.Fragment>
  );
});