import React, { useContext, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Owa } from '~/owa/OpenWebAnalyticsProvider';

// Components
import PermissionedView from './PermissionedView';
import ScrollViewMoreButton from '~/components/scroll/ScrollViewMoreButton';

export default function MainView({
  title = 'GeoIKP',
  children
}) {

  const tracker = useContext(Owa);
  const location = useLocation();

  useLayoutEffect(() => {

    if (!!tracker && !!location) {
      document.title = title;

      tracker.trackPageView(location.pathname);
    }
  }, [title, location, tracker]);

  return (
    <PermissionedView>

      <div className="flex-grow-1 h-100">
        {children}
      </div>

      <ScrollViewMoreButton label="View more..." btnColor="warning" />
    </PermissionedView>
  );
}