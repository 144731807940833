import React, { createContext, useReducer, useContext } from 'react';
import { createSymbiote } from 'redux-symbiote';

import {
  initialState as commonInitialState,
  symbiotes as commonSymbiotes
} from '../modules/main/store';

import {
  initialState as userInitialState,
  symbiotes as userSymbiotes
} from '../modules/user/store';

import {
  initialState as policyInitialState,
  symbiotes as policySymbiotes
} from '../modules/policy/store';

import {
  initialState as toolkitInitialState,
  symbiotes as toolkitSymbiotes
} from '../modules/toolkit/store';

import {
  initialState as nbsInitialState,
  symbiotes as nbsSymbiotes
} from '../modules/nbs/store';

// context object
const Store = createContext();


const initialState = ({
  ...commonInitialState,
  ...userInitialState,
  ...policyInitialState,
  ...toolkitInitialState,
  ...nbsInitialState
});

const symbiotes = ({
  ...commonSymbiotes,
  ...userSymbiotes,
  ...policySymbiotes,
  ...toolkitSymbiotes,
  ...nbsSymbiotes
});

const { actions, reducer } = createSymbiote(initialState, symbiotes);

function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = ({ state, dispatch });

  return (
    <Store.Provider value={value}>
      {children}
    </Store.Provider>
  );
}

function useStore() {
  return useContext(Store);
}

export {
  Store,
  StoreProvider,
  actions,
  useStore
};
