import React, { forwardRef } from 'react';
import { formatString } from '~/lib/Utils';

// Components
import NavigationItem from './NavigationItem';

export default forwardRef(function Crowdsourcing({
  url,
  label,
  iframe,
  content,
  user,
  profile
}, externalRef) {
  const token = !!user && user.token;
  const upid = !!profile && profile.id;

  const to = !!url && formatString(url, { token, upid }, { before: '{', after: '}' });

  return (
    <NavigationItem
      url={!!iframe ? '/nbs/crowdsourcing' : to}
      label={label}
      content={content}
      ref={externalRef}
    />)
});