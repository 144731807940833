import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useStore } from '~/store/StoreProvider';

const IndicatorStyled = styled.div`
  height: ${ /* sc-value */ ({ height }) => height}px;
  transition: width 0.5s;
  width: ${ /* sc-value */ ({ progress }) => Math.floor(progress * 100)}%;
`;

const ContainerStyled = styled.div`
  position: absolute;
  z-index: 2000;
  width: 100%;
  top: 0;
`;


export function PaceIndicator({ height, color, className, ...props }) {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress(prg => prg + (1 - prg) * 0.2);
    }, 500 + 200 * Math.random());

    return () => !!intervalId && clearInterval(intervalId);
  }, []);

  return (
    <ContainerStyled className={`${className} pace`} {...props}>
      <IndicatorStyled height={height} progress={progress} className={`pace-progress bg-${color}`} />
    </ContainerStyled>
  );
}

export default function PaceIndicatorContainer({ height = 3, color = 'primary', className = '', ...props }) {
  const { state } = useStore();
  return (
    <React.Fragment>
      {state.loading > 0 && (
        // this is required to guarantee desired lifecycle
        <PaceIndicator height={height} color={color} className={className} {...props} />
      )}
    </React.Fragment>
  )
}