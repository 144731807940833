import React, { useMemo, forwardRef, useCallback } from 'react';
import composeRefs from '@seznam/compose-react-refs';

import { stopPreventEvent, eventTargetBlur, is } from '~/lib/Utils';

import { usePermissions as usePermissionsContext } from '~/store/PermissionsProvider';
import { useGetCallback } from 'react-cached-callback';

export const ProfilePermissionType = {
  read: 'read',
  execute: 'execute'
};

export function permissionHasRequired(permission, required) {
  return Array.isArray(required)
    ? permission.actions.some(a => required.some(r => r === a))
    : permission.actions.includes(required);

  // return Array.isArray(required)
  //   ? required.includes(permission.name.split('.').last())
  //   : permission.name.split('.').last() === required;
}

/*
for example, there is a permission that includes 'execute' for one profile, and does not for another
we cannot block click/tap actions for all ProfileComponent children only because there is no 'execute' permission
so, to force check for 'execute' and enable/disable click/tap we have to specify this option explicitly in [required]
'read' is implicit, and a fact of using ProfileComponent says that permissions must contain such name with 'read'
*/
export default forwardRef(function ProfileComponent({
  name,
  required = [/* read, execute */], // by default 'read' is required, if 'execute' needs to be checked, include both 'read' and 'execute'
  children,
  component // when is specified, props.children is ignored
}, externalRef) {
  const permissions = usePermissionsContext();

  const permission = useMemo(() => {
    return !!permissions
      && permissions.find(p => p.name === name);
  }, [name, permissions]);

  const allowed = useMemo(() => {
    return !!permission // must be on profile permissions
      && !!permission.actions // at least 'read' will be there
      && (!required || !required.length || permissionHasRequired(permission, required));
  }, [permission, required]);

  const disabled = useMemo(() => {
    return !!permission // do not block if there are no permissions returned from server
      && permission.actions  // do not block, will be hidden instead (may be)
      && required.includes(ProfilePermissionType.execute)  // is not required to check and block
      && !permissionHasRequired(permission, ProfilePermissionType.execute);  // if is required, must be allowed in a profile
  }, [permission, required]);

  const getHandleExecuteHandler = useGetCallback(
    handler =>
      (...args) => {
        // here we either block the action or make nothing
        if (!!handler && !disabled) {
          handler(...args);
        } else if (!handler && disabled) {
          // capture click at upper level and prevent delegating it down a component tree
          stopPreventEvent(args[0]);
          eventTargetBlur(args[0]);
        }
        // otherwise click/tap will be ignored
      },
    handler => handler,
    [permission, disabled]
  );

  const profileProps = useMemo(() => {
    return (!!permission && ({
      ...permission.layout,
      content: permission.content
    })) || {};
  }, [permission]);

  const ref = useCallback(node => {
    !!node && node.setAttribute('data-permid', name);
  }, [name]);

  return (
    <React.Fragment>
      {is.func(component)
        ? cloneElement(name, component, profileProps, disabled, composeRefs(externalRef, ref), getHandleExecuteHandler, permissions, permission, allowed)
        : React.Children.map(children, (child, indx) => {
          return cloneElement(name, child, profileProps, disabled, composeRefs(externalRef, ref), getHandleExecuteHandler, permissions, permission, allowed);
        })
      }
    </React.Fragment>
  );
});


function cloneElement(name, element, props, disabled, ref, executeHandler, permissions, permission, allowed) {
  if (!is.func(element) && !React.isValidElement(element)) {
    return null;
  }

  if (!permissions) {
    const elem = is.func(element)
      ? element(props)
      : element;

    if (!elem) {
      return null;
    }

    return React.cloneElement(elem, {
      ...elem.props,
      ...props,
      'data-permid': name,
      ref: composeRefs(elem.props.ref, ref)
    });
  }

  if (!permission || !allowed || !permissionHasRequired(permission, ProfilePermissionType.read)) {
    return null;
  }

  // NOTE. In order to NOT render not allowed component call the callback function only if allowed
  const elem = is.func(element)
    ? element(props)
    : element;

  if (!elem) {
    return null;
  }

  return React.cloneElement(elem, {
    ...elem.props,
    ...props,
    'data-permid': name,
    ref: composeRefs(elem.props.ref, ref),
    disabled: elem.props.disabled || disabled,
    onClick: executeHandler(elem.props.onClick)
  });
}

export function usePermissions(name, requiredRights) {
  const permissions = usePermissionsContext();

  return useMemo(() => {
    if (!permissions || !permissions.length) {
      return true;
    }

    const checkPermission = pname => {
      const permission = permissions.find(p => p.name === pname);
      const allowed = !!permission
        && !!permission.actions // at least 'read' will be there
        && (!requiredRights || !requiredRights.length || permissionHasRequired(permission, requiredRights));

      return (!!allowed && !!permissionHasRequired(permission, ProfilePermissionType.read)) && permission;
    }

    if (!Array.isArray(name)) {
      return checkPermission(name);
    } else {
      return name.map(n => checkPermission(n));
    }


  }, [name, permissions, requiredRights]);
}