import React, { useMemo } from 'react';
import styled from 'styled-components';

import { is } from '~/lib/Utils';

// Components
import TabDetailsActivator from '~/components/tabs/tabs-control/TabDetailsActivator';

const DetailsTitleStyled = styled.div`
  font-size: 1.5rem;
  font-family: 'Raleway Extrabold';
  opacity: 0.9;
  letter-spacing: 0.25rem;
`;

const LayerColorStyled = styled.div`
  background-color: ${({ color }) => color};
  border: 2px solid var(--gwhite);
  width: 1rem;
  height: 1rem;
`;

function hasMoreValues({ values }) {
  return !!values
    && is.array(values)
    && values.every(v => is.object(v) && v.hasOwnProperty('name') && v.hasOwnProperty('rasterValue'));
}

export default function IndicatorListComponent({
  indicator
}) {
  const values = useMemo(() => {
    if (!!indicator?.suitableValues && !!indicator?.valueClasses) {
      const values = indicator.valueClasses
        .filter(v => indicator.suitableValues.some(s => s === v.code))
        .map(v => ({ ...v, hasMoreValues: hasMoreValues(v) }));

      // convert values without names
      return values.some(v => !v.name)
        ? values.map(v => v.values).flaten()
        : values;
    }
  }, [indicator]);

  const details = useMemo(() => {
    if (!!indicator && values.some(v => v.hasMoreValues)) {
      return ([
        indicator.name,
        true,
        <div className="d-flex flex-column justify-content-start align-items-center">
          <React.Fragment>
            <DetailsTitleStyled className="text-gsecondary-alt2 font-weight-bold text-uppercase my-5">
              {indicator.attGroup}
            </DetailsTitleStyled>
            <div className="mx-10 pb-8 text-gsecondary-alt2 d-flex flex-row justify-content-start flex-wrap">
              {values.map((value, indx) => (
                <div key={`indicator-list-value-${indx}`} className="p-5 text-left" style={{ lineHeight: '1.3rem' }}>
                  <strong className="text-gprimary mb-3" style={{ lineHeight: '3rem' }}>{value.name}</strong>
                  {value.values.map((val, vindx) => (
                    <div key={`value-${indx}-${vindx}`} className=" d-flex flex-row justify-content-start">
                      <div className="text-left">&bull; {val.name}</div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </React.Fragment>
        </div>
      ]);
    }
  }, [indicator, values]);

  return (
    <div className="h-100 p-5 d-flex flex-column justify-content-start align-items-center">
      <ul>
        {!!values && values.map((value, indx) => (
          <li
            key={`indicator-list-${indx}`}
            className={`text-gsecondary-alt2 ${!!value.color && 'd-flex'} flex-row justify-content-start align-items-center`}>
            {!!value.color && (
              <LayerColorStyled color={value.color} className="mr-2" />
            )}
            <div>
              {value.name}{!!value.caption ? ` [${value.caption}]` : ''}
            </div>
          </li>
        ))}
      </ul>

      {values?.some(v => v.hasMoreValues) && (
        <TabDetailsActivator
          className="d-inline-block text-gprimary-alt text-uppercase text-nowrap font-weight-bold pointable mt-5"
          tabDetails={details}
        >
          read more&nbsp;&nbsp;&gt;
        </TabDetailsActivator>
      )}
    </div>
  );
}