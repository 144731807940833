import React from 'react';

import { useProfileSelect, ProfileIconUrls } from '../../../lib/Utils.Main';

// Components
import Modal from '~/components/modal/Modal';

export default function ProfileSelectModal({
  show,
  profiles,
  currentProfile,
  user,
  onProfileSelect,
  onClose
}) {

  const getHandleProfileSelected = useProfileSelect(onProfileSelect);

  return (
    <Modal
      id="profile-select-modal"
      title="Select profile"
      bodyClassName="bg-gwhite"
      show={show}
      size="lg"
      onClose={onClose}
    >
      {!!profiles && !!profiles.length && (
        <div className="list-group list-group-flush text-gsecondary">
          {profiles.map((profile, indx) => {
            const selected = currentProfile?.id === profile.id;
            const profileIconUrl = ProfileIconUrls[profile.id];
            const original = user?.userProfileId === profile.id && profile.id !== currentProfile?.id;

            return (
              <div
                key={`profile-${indx}`}
                className={`list-group-item d-flex align-items-center pointable ${selected ? 'text-gprimary' : ''} ${original ? 'btn-pill mx-n5 px-5 bg-gprimary-desat-alt2' : ''}`}
                data-dismiss="modal"
                onClick={getHandleProfileSelected(profile)}
              >

                <div className="mr-5">
                  {!!profileIconUrl && (<img src={profileIconUrl} alt=".." width="64" />)}
                </div>
                <div className="mr-auto">
                  <p className="font-weight-bold mb-1">
                    {profile.name}
                  </p>

                  <p className="font-size-sm text-muted mb-0">
                    {profile.description}
                  </p>
                </div>

                {selected && (
                  <div className="badge badge-rounded-circle badge-primary ml-4">
                    <i className="fe fe-check"></i>
                  </div>
                )}

              </div>
            );
          })}
        </div>
      )}
    </Modal>
  );
}


