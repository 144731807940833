import React from 'react';

// Components
import { StyleSheet, View, Text, Link } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

const Styles = StyleSheet.create({
  dataset: {
    marginBottom: 3
  }
});

export default function Datasets({
  datasets
}) {
  return (
    <Paragraph title="Datasets">
      {datasets?.map((dataset, indx) => (
        <View key={`dataset-${indx}`} style={Styles.dataset}>
          <Text>{dataset.title || dataset.name /* specify when available */}</Text>
          <Text>Atavilable at: <Link src={dataset.url}>{dataset.url}</Link></Text>
        </View>
      ))}
    </Paragraph>
  );
}