import React from 'react';

// Components
import Section from '../Section';

import References from './Sources/References';
import Contributor from './Sources/Contributor';
import Datasets from './Sources/Datasets';

export default function Sources({
  intervention
}) {
  return (
    <Section title="Sources">
      {!!intervention.interventionReferences && (
        <References interventionReferences={intervention.interventionReferences} />
      )}

      {!!intervention?.contributor && (
        <Contributor contributor={intervention.contributor} />
      )}

      {!!intervention.datasets?.map && ( /* can be not an array but an unexpected string in a DB */
        <Datasets datasets={intervention.datasets} />
      )}
    </Section>
  );
}