import React from 'react';

import { useLocation, Route, Redirect } from 'react-router-dom';
import { useStore } from '../store/StoreProvider';

export default function AuthenticatedRoute({ redirectTo = '/login', children, ...rest }) {
  const { state } = useStore();
  const location = useLocation();

  return (
    <React.Fragment>
      {!!state.user.data ? (
        <Route {...rest}>
          {children}
        </Route>
      ) : (
        <Redirect
          to={{
            pathname: redirectTo,
            state: { from: location }
          }}
        />
      )}
    </React.Fragment>
  );
}