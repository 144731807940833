import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import { useStore } from '../store/StoreProvider';

export default function AnonymousRoute({ redirectTo = '/home', children, ...rest }) {
  const { state } = useStore();

  return (
    <React.Fragment>
      {!state.user || !state.user.data ? (
        <Route {...rest}>
          {children}
        </Route>
      ) : (
        <Redirect
          to={{
            pathname: redirectTo
          }}
        />
      )}
    </React.Fragment>
  );
}