import React, { lazy, useMemo, useRef, useEffect } from 'react';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';

import AuthenticatedRoute from './AuthenticatedRoute';
import AnonymousRoute from './AnonymousRoute';
import ProfileSelectorRedirect from './ProfileSelectorRedirect';

import { is } from '~/lib/Utils';

import { HelpContexts } from '~/components/help/HelpProvider';

// Components
import MainView from '~/modules/main/views/MainView';
import MainView2 from '~/modules/main/views/MainView2';
import MainViewNoHeader from '~/modules/main/views/MainViewNoHeader';
import ModalPanelView from '~/modules/main/views/ModalPanelView';

import LogInPage from '~/modules/user/pages/LogInPage';
import HelpPage from '~/modules/main/pages/HelpPage';

import NotFoundPage from '~/modules/main/pages/NotFoundPage';
import NotAuthorizedPage from '~/modules/main/pages/NotAuthorizedPage';

import PageLoadingPlaceholder from '~/modules/main/components/App/PageLoadingPlaceholder';
import PaceIndicator from '~/modules/main/components/App/PaceIndicator';

import InterventionPage from '~/modules/toolkit/components/InterventionPage/InterventionPage';


// Main
const WelcomePageLoadable = lazy(() => import(/* webpackChunkName:"home" */ '~/modules/main/pages/WelcomePage.js'));
const WelcomePage2Loadable = lazy(() => import(/* webpackChunkName:"home" */ '~/modules/main/pages/WelcomePage2.js'));
const HomePageLoadable = lazy(() => import(/* webpackChunkName:"home" */ '~/modules/main/pages/HomePage.js'));
const HomePage2Loadable = lazy(() => import(/* webpackChunkName:"home" */ '~/modules/main/pages/HomePage2.js'));
const AboutPageLoadable = lazy(() => import(/* webpackChunkName:"operandum" */ '~/modules/main/pages/AboutPage.js'));
const AboutPageLoadable2 = lazy(() => import(/* webpackChunkName:"operandum" */ '~/modules/main/pages/AboutPage2.js'));
const ContactPageLoadable = lazy(() => import(/* webpackChunkName:"operandum" */ '~/modules/main/pages/ContactPage.js'));
const PartnerPageLoadable = lazy(() => import(/* webpackChunkName:"operandum" */ '~/modules/main/pages/PartnerPage.js'));
const PrivacyPolicyPageLoadable = lazy(() => import(/* webpackChunkName:"legal" */ '~/modules/main/pages/PrivacyPolicyPage.js'));
const CookiePolicyPageLoadable = lazy(() => import(/* webpackChunkName:"legal" */ '~/modules/main/pages/CookiePolicyPage.js'));

// NBS
const NbsExplorerPageLoadable = lazy(() => import(/* webpackChunkName:"nbs" */ '~/modules/nbs/pages/ExplorerPage.js'));
const NbsExplorerPageLoadable2 = lazy(() => import(/* webpackChunkName:"nbs" */ '~/modules/nbs/pages/ExplorerPage2.js'));
const NbsLifecyclePageLoadable = lazy(() => import(/* webpackChunkName:"nbs" */ '~/modules/nbs/pages/LifecyclePage.js'));
const NbsLifecyclePageLoadable2 = lazy(() => import(/* webpackChunkName:"nbs" */ '~/modules/nbs/pages/LifecyclePage2.js'));
const NbsExplorerComparePageLoadable = lazy(() => import(/* webpackChunkName: "nbs" */ '~/modules/nbs/pages/ExplorerComparePage.js'));
const CrowdsourcingPageLoadable = lazy(() => import(/* webpackChunkName: "nbs" */ '~/modules/nbs/pages/CrowdsourcingPage.js'));
const CrowdsourcingReviewPageLoadable = lazy(() => import(/* webpackChunkName: "nbs" */ '~/modules/nbs/pages/CrowdsourcingReviewPage.js'));

// Data
const DataMapPageLoadable = lazy(() => import(/* webpackChunkName:"data" */  '~/modules/data/pages/DataMapPage.js'));
const DataMapPageLoadable2 = lazy(() => import(/* webpackChunkName:"data" */  '~/modules/data/pages/DataMapPage2.js'));
const BusinessNetworkingPageLoadable = lazy(() => import(/* webpackChunkName:"data" */ '~/modules/data/pages/BusinessNetworkingPage.js'));
const CitizenStoryPageLoadable = lazy(() => import(/* webpackChunkName:"data" */ '~/modules/data/pages/CitizenStory'));
const MapLegendsModalLoadable = lazy(() => import(/* webpackChunkName:"data" */ '~/modules/data/pages/MapLegendsModal'));

// OAL
const OalExplorerPageLoadable = lazy(() => import(/* webpackChunkName:"oal" */ '~/modules/oal/pages/ExplorerPage.js'));
const OalExplorerPageLoadable2 = lazy(() => import(/* webpackChunkName:"oal" */ '~/modules/oal/pages/ExplorerPage2.js'));
const OalExplorerPageLoadable3 = lazy(() => import(/* webpackChunkName:"oal" */ '~/modules/oal/pages/ExplorerPage3.js'));

// Policy
const PolicyCataloguePageLoadable = lazy(() => import(/* webpackChunkName:"policy" */ '~/modules/policy/pages/PolicyCataloguePage.js'));
const PolicyCataloguePageLoadable2 = lazy(() => import(/* webpackChunkName:"policy" */ '~/modules/policy/pages/PolicyCataloguePage2.js'));
const PolicyPermittingPathPageLoadable = lazy(() => import(/* webpackChunkName:"policy"  */ '~/modules/policy/pages/PolicyPermittingPathPage.js'));
const PolicyPermittingPathPageLoadable2 = lazy(() => import(/* webpackChunkName:"policy"  */ '~/modules/policy/pages/PolicyPermittingPathPage2.js'));
const PolicyAddPageLoadable = lazy(() => import(/* webpackChunkName:"policy" */ '~/modules/policy/pages/PolicyAddPage.js'));

// Toolkit
const DataToolkitPageLoadable = lazy(() => import(/* webpackChunkName:"data" */  '~/modules/toolkit/pages/ToolkitPage.js'));
const DataToolkitPageLoadable2 = lazy(() => import(/* webpackChunkName:"data" */  '~/modules/toolkit/pages/ToolkitPage2.js'));
const DataToolkitInterventionPageLoadable = lazy(() => import(/* webpackChunkName:"data" */  '~/modules/toolkit/pages/InterventionPage.js'));
const DataToolkitInterventionDetailsPageLoadable = lazy(() => import(/* webpackChunkName:"data" */  '~/modules/toolkit/pages/InterventionDetailPage.js'));
const DataToolkitComparePageLoadable = lazy(() => import(/* webpackChunkName: "nbs" */ '~/modules/toolkit/pages/ComparePage.js'));
const DataToolkitCoCreationPageLoadable = lazy(() => import(/* webpackChunkName: "nbs" */ '~/modules/toolkit/pages/CoCreationPage.js'));
const DataToolkitFuturePageLoadable = lazy(() => import(/* webpackChunkName: "nbs" */ '~/modules/toolkit/pages/FuturePage.js'));

// User
const SignUpPageLoadable = lazy(() => import(/* webpackChunkName:"user" */ '~/modules/user/pages/SignUpPage.js'));
const UserProfilePageLoadable = lazy(() => import(/* */ '~/modules/user/pages/ProfilePage.js'));

// Modals
const PolicyRelatedNbsModalLodable = lazy(() => import(/* webpackChunkName: "policy" */ '~/modules/policy/pages/PolicyRelatedNbsModal.js'));
const PolicySimilarPolicyModalLoadable = lazy(() => import(/* webpackChunkName: "policy" */ '~/modules/policy/pages/PolicySimilarPolicyModal.js'));
const PolicyRelatedDataModalLoadable = lazy(() => import(/* webpackChunkName: "policy" */ '~/modules/policy/pages/PolicyRelatedDataModal.js'));
const OalExplorerPageModalLoadable = lazy(() => import(/* webpackChunkName:"oal" */ '~/modules/oal/pages/ExplorerModal.js'));

const PageLoadingPlaceholderMemoized = React.memo(PageLoadingPlaceholder);

const isLocationModal = (location) =>
  is.notUndef(location)
  && is.notUndef(location.pathname)
  && location.pathname.startsWith('/modal/');

const isLocationAuthentication = (location) =>
  is.notUndef(location)
  && is.notUndef(location.pathname)
  && location.pathname.startsWith('/login');

function usePreviousLocation(location, history) {
  const previousLocation = useRef(location);

  useEffect(() => {
    if (!isLocationModal(location) && !isLocationAuthentication(location)) {
      previousLocation.current = location;
    }
  }, [location, history]);

  return previousLocation.current;
}

export default function Routes() {
  const location = useLocation();
  const history = useHistory();

  const previousLocation = usePreviousLocation(location, history);

  const isModal = useMemo(() => {
    return isLocationModal(location) && location.key !== previousLocation.key;
  }, [location, previousLocation]);


  return (
    <React.Fragment>
      <PaceIndicator />
      <React.Suspense fallback={<PageLoadingPlaceholderMemoized />}>
        <Switch location={isModal ? previousLocation : location}>
          {/*
          <Route path="/" exact={true}>
            <WelcomePageLoadable />
          </Route>
          */}

          <AnonymousRoute path="/old-welcome" exact={true}>
            <MainViewNoHeader title="Welcome">
              <WelcomePageLoadable />
            </MainViewNoHeader>
          </AnonymousRoute>

          <AnonymousRoute path="/" exact={true}>
            <MainViewNoHeader title="Welcome">
              <WelcomePage2Loadable />
            </MainViewNoHeader>
          </AnonymousRoute>

          <Route
            path={["/scientist/", "/citizen/", "/business/", "/policymaker/", "/association/", "/newsmedia/"]}
            exact={false}
            strict={true}
          >
            <ProfileSelectorRedirect />
          </Route>

          <Route path="/old-home" exact={true}>
            <MainView title="Home">
              <HomePageLoadable />
            </MainView>
          </Route>

          <Route path="/home" exact={true}>
            <MainView2 title="Home" helpContext={HelpContexts.home}>
              <HomePage2Loadable />
            </MainView2>
          </Route>

          <Route path="/login" exact={true}>
            <MainView2 title="Log In" helpContext={HelpContexts.login}>
              <LogInPage />
            </MainView2>
          </Route>

          <Route path="/help" exact={true}>
            <MainView2 title="Help">
              <HelpPage />
            </MainView2>
          </Route>

          <Route path="/about" exact={true}>
            <MainView2 title="About">
              <AboutPageLoadable2 />
            </MainView2>
          </Route>

          <Route path="/old-about" exact={true}>
            <MainView title="About">
              <AboutPageLoadable />
            </MainView>
          </Route>

          <Route path="/partner" exact={true}>
            <MainView2 title="Partners">
              <PartnerPageLoadable />
            </MainView2>
          </Route>

          <Route path="/contact" exact={true}>
            <MainView2 title="Contacts">
              <ContactPageLoadable />
            </MainView2>
          </Route>

          <Route path="/signup" exact={true}>
            <MainView2 title="Sign Up" helpContext={HelpContexts.register}>
              <SignUpPageLoadable />
            </MainView2>
          </Route>

          <AuthenticatedRoute path="/user/profile" exact={true}>
            <MainView2 title="Profile">
              <UserProfilePageLoadable />
            </MainView2>
          </AuthenticatedRoute>

          <Route path="/legal/privacy" exact={true}>
            <MainView2 title="Privacy">
              <PrivacyPolicyPageLoadable />
            </MainView2>
          </Route>

          <Route path='/legal/cookie' exact={true}>
            <MainView2 title="Cookies">
              <CookiePolicyPageLoadable />
            </MainView2>
          </Route>

          <Route path="/nbs/explorer/compare" exact={true}>
            <MainView2 title="NBS Explorer" helpContext={HelpContexts.nbsExplorer}>
              <NbsExplorerComparePageLoadable />
            </MainView2>
          </Route>

          <Route path="/nbs/explorer/id/:ids?" exact={true}>
            <MainView2 title="NBS Explorer" helpContext={HelpContexts.nbsExplorer}>
              <NbsExplorerPageLoadable2 />
            </MainView2>
          </Route>

          <Route path="/nbs/explorer/details/:nbsId" exact={true}>
            <MainView2 title="NBS Details" helpContext={HelpContexts.nbsExplorer}>
              <NbsExplorerPageLoadable2 />
            </MainView2>
          </Route>

          <Route path="/nbs/explorer/:search?" exact={true}>
            <MainView2 title="NBS Explorer" helpContext={HelpContexts.nbsExplorer}>
              <NbsExplorerPageLoadable2 />
            </MainView2>
          </Route>

          <Route path="/nbs/old-explorer/:search?" exact={true}>
            <MainView title="NBS Explorer">
              <NbsExplorerPageLoadable />
            </MainView>
          </Route>

          <Route path="/nbs/crowdsourcing/:nbsid?" exact={true}>
            <MainView2 title="NBS Catalogue" helpContext={HelpContexts.nbsCatalogue}>
              <CrowdsourcingPageLoadable />
            </MainView2>
          </Route>

          <Route path="/nbs/review/:nbsid?" exact={true}>
            <MainView2 title="NBS Catalogue" helpContext={HelpContexts.nbsCatalogue}>
              <CrowdsourcingReviewPageLoadable />
            </MainView2>
          </Route>

          <Route path="/nbs/lifecycle" exact={true}>
            <MainView2 title="NBS LifeCycle">
              <NbsLifecyclePageLoadable2 />
            </MainView2>
          </Route>

          <Route path="/nbs/old-lifecycle" exact={true}>
            <MainView title="NBS LifeCycle">
              <NbsLifecyclePageLoadable />
            </MainView>
          </Route>

          <Route path="/data/toolkit/intervention/:id" exact={true}>
            <MainView2 title="NBS Toolkit" helpContext={HelpContexts.toolkit}>
              <DataToolkitInterventionDetailsPageLoadable />
            </MainView2>
          </Route>
          <Route path="/nbs/toolkit/intervention/:id" exact={true}>
            <MainView2 title="NBS Toolkit" helpContext={HelpContexts.toolkit}>
              <DataToolkitInterventionDetailsPageLoadable />
            </MainView2>
          </Route>

          <Route path="/data/toolkit/intervention" exact={true}>
            <MainView2 title="NBS Toolkit" helpContext={HelpContexts.toolkit}>
              <DataToolkitInterventionPageLoadable />
            </MainView2>
          </Route>
          <Route path="/nbs/toolkit/intervention" exact={true}>
            <MainView2 title="NBS Toolkit" helpContext={HelpContexts.toolkit}>
              <DataToolkitInterventionPageLoadable />
            </MainView2>
          </Route>

          <Route path="/data/old-toolkit" exact={true}>
            <MainView title="NBS Toolkit">
              <DataToolkitPageLoadable />
            </MainView>
          </Route>
          <Route path="/nbs/old-toolkit" exact={true}>
            <MainView title="NBS Toolkit">
              <DataToolkitPageLoadable />
            </MainView>
          </Route>

          <Route path="/data/toolkit" exact={true}>
            <MainView2 title="NBS Toolkit" helpContext={HelpContexts.toolkit}>
              <DataToolkitPageLoadable2 />
            </MainView2>
          </Route>
          <Route path="/nbs/toolkit" exact={true}>
            <MainView2 title="NBS Toolkit" helpContext={HelpContexts.toolkit}>
              <DataToolkitPageLoadable2 />
            </MainView2>
          </Route>

          <Route path="/data/toolkit/compare" exact={true}>
            <MainView2 title="NBS Toolkit" helpContext={HelpContexts.toolkit}>
              <DataToolkitComparePageLoadable />
            </MainView2>
          </Route>
          <Route path="/nbs/toolkit/compare" exact={true}>
            <MainView2 title="NBS Toolkit" helpContext={HelpContexts.toolkit}>
              <DataToolkitComparePageLoadable />
            </MainView2>
          </Route>

          <Route path="/data/toolkit/cocreation" exact={true}>
            <MainView2 title="NBS Toolkit" helpContext={HelpContexts.toolkit}>
              <DataToolkitCoCreationPageLoadable />
            </MainView2>
          </Route>
          <Route path="/nbs/toolkit/cocreation" exact={true}>
            <MainView2 title="NBS Toolkit" helpContext={HelpContexts.toolkit}>
              <DataToolkitCoCreationPageLoadable />
            </MainView2>
          </Route>

          <Route path="/data/toolkit/future" exact={true}>
            <MainView2 title="NBS Toolkit" helpContext={HelpContexts.toolkit}>
              <DataToolkitFuturePageLoadable />
            </MainView2>
          </Route>
          <Route path="/nbs/toolkit/future" exact={true}>
            <MainView2 title="NBS Toolkit" helpContext={HelpContexts.toolkit}>
              <DataToolkitFuturePageLoadable />
            </MainView2>
          </Route>

          <Route path="/data/map/:center?" exact={true}>
            <MainView2 title="NBS Data Map" helpContext={HelpContexts.map}>
              <DataMapPageLoadable2 />
            </MainView2>
          </Route>

          <Route path="/nbs/toolkit/pdf" exact={true}>
            <InterventionPage />
          </Route>

          <Route path="/data/old-map" exact={true}>
            <MainView title="NBS Data Map">
              <DataMapPageLoadable />
            </MainView>
          </Route>

          <Route path="/data/networking/:network?" exact={true}>
            <MainView2 title="Networking" helpContext={HelpContexts.businessNetworking}>
              <BusinessNetworkingPageLoadable />
            </MainView2>
          </Route>

          <Route path="/data/story/:site?" exact={true}>
            <MainView2 title="Citizens' Stories" helpContext={HelpContexts.stories}>
              <CitizenStoryPageLoadable />
            </MainView2>
          </Route>

          <Route path="/data/story/id/:id" exact={true}>
            <MainView2 title="Citizens' Stories" helpContext={HelpContexts.stories}>
              <CitizenStoryPageLoadable />
            </MainView2>
          </Route>

          <Route path="/oal/explorer/:site?" exact={true}>
            <MainView2 title="OAL Explorer" helpContext={HelpContexts.oalExplorer}>
              <OalExplorerPageLoadable2 />
            </MainView2>
          </Route>

          <Route path="/oal/explorer2/:site?" exact={true}>
            <MainView2 title="OAL Explorer" helpContext={HelpContexts.oalExplorer}>
              <OalExplorerPageLoadable3 />
            </MainView2>
          </Route>

          <Route path="/oal/old-explorer/:site?" exact={true}>
            <MainView2 title="OAL Explorer">
              <OalExplorerPageLoadable />
            </MainView2>
          </Route>

          <Route path="/policy/add" exact={true}>
            <MainView2 title="Policy Catalogue" helpContext={HelpContexts.policyCatalogue}>
              <PolicyAddPageLoadable />
            </MainView2>
          </Route>

          <Route path="/policy/catalogue/:search?" exact={true}>
            <MainView2 title="Policy Catalogue" helpContext={HelpContexts.policyCatalogue}>
              <PolicyCataloguePageLoadable2 />
            </MainView2>
          </Route>

          <Route path="/policy/catalogue" exact={true}>
            <MainView2 title="Policy Catalogue" helpContext={HelpContexts.policyCatalogue}>
              <PolicyCataloguePageLoadable2 />
            </MainView2>
          </Route>
          <Route path="/policy/old-catalogue" exact={true}>
            <MainView title="Policy Catalogue">
              <PolicyCataloguePageLoadable />
            </MainView>
          </Route>

          <Route path="/policy/old-path" exact={true}>
            <MainView title="Permitting Path">
              <PolicyPermittingPathPageLoadable />
            </MainView>
          </Route>

          <Route path="/policy/path" exact={true}>
            <MainView2 title="Permitting Path" helpContext={HelpContexts.permittingPath}>
              <PolicyPermittingPathPageLoadable2 />
            </MainView2>
          </Route>

          <Route path="/notauthorized" exact={true}>
            <MainViewNoHeader title="Not Authorized">
              <NotAuthorizedPage />
            </MainViewNoHeader>
          </Route>

          <Route>
            <MainViewNoHeader title="Not Found">
              <NotFoundPage />
            </MainViewNoHeader>
          </Route>
        </Switch>

        {isModal && (
          <Switch location={location}>
            <Route path="/modal/policy/related-nbs" exact={true}>
              <PolicyRelatedNbsModalLodable />
            </Route>

            <Route path="/modal/policy/similar-policy/:bbox?" exact={true}>
              <PolicySimilarPolicyModalLoadable />
            </Route>

            <Route path="/modal/policy/related-data/:bbox?" exact={true}>
              <PolicyRelatedDataModalLoadable />
            </Route>

            <Route path="/modal/data/map/legends/:legends?" exact={true}>
              <MapLegendsModalLoadable />
            </Route>

            <Route path="/modal/oal/explorer/:site" exact={true}>
              <ModalPanelView>
                <OalExplorerPageModalLoadable />
              </ModalPanelView>
            </Route>
          </Switch>
        )}
      </React.Suspense>
    </React.Fragment>
  )
}