import { is } from './Utils';

export function updateState(state, path, value) {
  if (!is.array(path) || typeof value !== 'object') {
    // TODO: warn user
    return state; // no mutations
  }

  if (!path.length) {
    return ({
      ...state,
      ...value
    });
  } else {
    const key = path.shift();

    const result = ({
      ...state,
      [key]: updateState(state[key], path, value)
    });

    return result;
  }
}

export function loadState(key, defaultObject) {
  if (is.nullOrEmptyString(key)) {
    return defaultObject;
  }

  const item = localStorage.getItem(key);
  if (is.nullOrEmptyString(item)) {
    return defaultObject;
  }

  return JSON.parse(item);
}

function _decorateSymbioteMethod(storage, selector, method) {
  return (...args) => {
    const state = method(...args);
    localStorage.setItem(storage, JSON.stringify(is.func(selector) ? selector(state) : state));

    return state;
  }
}

function _decorateSymbiote(storage, selector, symbiote) {
  return Object.keys(symbiote).reduce(
    (result, key) => {
      const method = symbiote[key];

      result[key] = is.object(method)
        ? _decorateSymbiote(storage, selector, method)
        : is.func(method)
          ? _decorateSymbioteMethod(storage, selector, method)
          : method;

      return result;
    },
    {});
}

export function makePersistent(key, selector, symbiote) {
  if (is.nullOrEmptyString(key)) {
    return symbiote;
  }

  return _decorateSymbiote(key, selector, symbiote);
}