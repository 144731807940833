import React, { createContext, useCallback, useState } from 'react';

export const Tabs = createContext();

export default function TabsProvider({
  children
}) {

  const [activeTab, setActiveTab] = useState(null);
  const [tabs, setTabs] = useState(null);

  const handleTabSelect = useCallback((tab) => {
    setActiveTab(tab);
  }, []);

  const handleTabRegister = useCallback((tab) => {
    setTabs(tabs => {
      if (!!tabs) {
        return !!tabs.find(t => t === tab) ? tabs : tabs.concat(tab)
      } else {
        setActiveTab(tab);
        return [tab];
      }
    });
  }, []);


  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState(null);

  const handleDetailsShowToggle = useCallback((title, large, content) => {
    setDetails({ title, large, content });
    setShowDetails(status => !status);
  }, []);

  const value = ({
    activeTab,
    tabs,
    tabSelect: handleTabSelect,
    tabRegister: handleTabRegister,
    details,
    showDetails,
    showDetailsChange: handleDetailsShowToggle,
  });

  return (
    <Tabs.Provider value={value}>
      {children}
    </Tabs.Provider>
  );
}