import React, { createContext, useContext } from 'react';

const Permissions = createContext();

function PermissionsProvider({ permissions, children }) {
  return (
    <Permissions.Provider value={permissions}>
      {children}
    </Permissions.Provider>
  );
}

function usePermissions() {
  return useContext(Permissions);
}

export {
  Permissions,
  PermissionsProvider,
  usePermissions
}