import React from 'react';

// Components
import { Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

export default function TimeTillBenefits({
  timeTillBenefits
}) {
  return (
    <Paragraph title="Time Till Benefits">
      <Text>{timeTillBenefits}</Text>
    </Paragraph>
  );
}