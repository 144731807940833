import { updateState } from '~/lib/Utils.Store.js';

export const initialState = {
  toolkit: {
    interventions: null,
    geoJson: null,
    hazards: null,
    compareItems: null
  }
};

export const symbiotes = {
  toolkit: {
    interventionsChange: (state, interventions) => updateState(state, ['toolkit'], { interventions }),
    interventionsClear: state => updateState(state, ['toolkit'], { interventions: initialState.toolkit.interventions }),
    geoJsonChange: (state, geoJson) => updateState(state, ['toolkit'], { geoJson }),
    geoJsonClear: (state) => updateState(state, ['toolkit'], { geoJson: initialState.toolkit.geoJson }),
    hazardsChange: (state, hazards) => updateState(state, ['toolkit'], { hazards }),
    hazardsClear: (state) => updateState(state, ['toolkit'], { hazards: initialState.toolkit.hazards }),
    compareItemsChange: (state, compareItems) => updateState(state, ['toolkit'], { compareItems }),
    compareItemsClear: (state) => updateState(state, ['toolkit'], { compareItems: initialState.toolkit.compareItems }),
    compareItemsToggle: (state, intervention) => updateState(state, ['toolkit'], {
      compareItems: state.toolkit.compareItems?.some(ci => ci.id === intervention?.id)
        ? state.toolkit.compareItems.filter(ci => ci.id !== intervention?.id)
        : (state.toolkit.compareItems || []).concat(intervention)
    })
  }
};