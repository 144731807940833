import React from 'react';
import { Document, pdf } from '@react-pdf/renderer'


// Component
import { PermissionsProvider } from '~/store/PermissionsProvider';
import InterventionPdfDocument from '../InterventionPdf/Document';

export function InterventionDocument({ intervention, permissions }) {
  if (!intervention) {
    return null;
  }

  // language is in ISO 639 code

  return (
    <PermissionsProvider permissions={permissions}>
      <Document
        title={intervention.name}
        author="GeoIKP"
        subject="NBS Toolkit Intervention"
        language="en"
        keywords="nbs,hazard,intervention,implementation,suitability"
      >
        <InterventionPdfDocument intervention={intervention} />
      </Document>
    </PermissionsProvider>
  );
}

export async function getPdfBlob(intervention, permissions) {
  return pdf(InterventionDocument({ intervention, permissions })).toBlob();
}