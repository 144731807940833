import React, { useEffect, useRef } from 'react';
import { createGlobalStyle } from 'styled-components';


const ParallaxGlobarOverrides = createGlobalStyle`
  .jarallax img {
    top: ${/* sc-value */ ({ imgShift }) => imgShift}px !important;
  }
`;

export default function Parallax({ id, imgSrc, imgSize, speed = 0.8, className = '', children, imgShift = 0 }) {
  const parallaxRef = useRef(null);

  useEffect(() => {
    const elem = parallaxRef.current;
    if (!!elem) {
      window.jarallax(elem, {
        speed,
        imgSrc,
        imgSize
      });

      return () => {
        window.jarallax(elem, 'destroy');
      }
    }
  }, [speed, id, imgSrc, imgSize, parallaxRef]);

  return (
    <React.Fragment>
      <ParallaxGlobarOverrides imgShift={imgShift} />

      <div
        ref={parallaxRef}
        className={`jarallax ${className}`}
        style={{ backgroundSize: 'contain', backgroundPosition: 'top' }}
      >
        {children}
      </div>
    </React.Fragment>
  )
}