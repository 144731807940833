import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import DOMPurify from 'dompurify';

import Routes from '~/router/Routes.js';

import ConfigurationProvider from '~/config';
import { StoreProvider } from '~/store/StoreProvider';
import { ToastProvider } from 'react-toast-notifications';
import { LocalizationProvider } from '~/i18n/LocalizationProvider';
import { OpenWebAnalyticsProvider } from '~/owa/OpenWebAnalyticsProvider';
import { GuideProvider } from '../components/guide/GuideProvider';

import CookieBanner from '~/modules/main/components/App/CookieBanner';
import ToastContainer from '~/components/notify/ToastContainer';

import L from 'leaflet';

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }
});

L.Map.addInitHook(function () {
  // Store a reference of the Leaflet map object on the map container,
  // so that it could be retrieved from DOM selection.
  // https://leafletjs.com/reference-1.3.4.html#map-getcontainer
  this.getContainer()._leaflet_ = this;
});

export default function App() {
  return (
    <ConfigurationProvider>
      <StoreProvider>
        <ToastProvider autoDismiss={true} components={{ ToastContainer }}>
          <Router>
            <LocalizationProvider>
              <OpenWebAnalyticsProvider
                siteId={process.env.REACT_APP_OWA_SITEID}
                trackPageView={true}
                trackClicks={true}
                trackAction={true}
              >
                <GuideProvider>
                  <Routes />
                </GuideProvider>
              </OpenWebAnalyticsProvider>
            </LocalizationProvider>
          </Router>
        </ToastProvider>
        <CookieBanner />
      </StoreProvider>
    </ConfigurationProvider>
  );
}
