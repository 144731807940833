import React from 'react';
import htmlParser from 'html-react-parser';

// Components
import { StyleSheet, View, Text, Link } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

const Styles = StyleSheet.create({
  funding: {
    display: 'flex',
    flexDirection: 'column',
    link: {
      marginBottom: 4
    }
  }
});

function parseFunding(funding) {
  return !!funding && htmlParser(
    funding,
    {
      trim: true,
      replace: ({ type, name, attribs, children, data }) => {
        const text = children?.find(c => c.type === 'text' && !!c.data);

        return type === 'tag' && name === 'a' && !!attribs?.href
          ? (
            <Text style={Styles.funding.link}>
              {!!text && (
                <Text style={{ marginRight: 2 }}>{text.data}: </Text>
              )}
              <Link src={attribs.href}>{attribs.href}</Link>
            </Text>
          ) : type === 'text' && !!data?.trim()
            ? (
              <Text style={Styles.funding.link}>
                {data.split(';').map((d, indx) => (
                  <Text key={`funding-${indx}`}>{d}</Text>
                ))}
              </Text>
            ) : <React.Fragment />
      }
    }
  )
}

export default function Funding({
  funding
}) {
  return (
    <Paragraph title="Funding Options">
      <View style={Styles.funding}>
        {parseFunding(funding)}
      </View>
    </Paragraph>
  );
}