import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';

// Components
import App from "./containers/App";

import '~/styles/theme.scss';
import '~/styles/index.css';

// Some third party libraries provides invalid prop types (like node|boolean)
// This type of warnings will be skipped
const originalErrorHandler = console.error;
console.error = errormessage => !errormessage.toString().includes("Warning: Failed prop type:") && originalErrorHandler(errormessage);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
