import { updateState } from '~/lib/Utils.Store.js';

export const initialState = {
  nbs: {
    nbs: null,
    compareItems: null
  }
};

export const symbiotes = {
  nbs: {
    compareItemsChange: (state, compareItems) => updateState(state, ['nbs'], { compareItems }),
    compareItemsClear: (state) => updateState(state, ['nbs'], { compareItems: initialState.nbs.compareItems }),
    compareItemsToggle: (state, nbs) => updateState(state, ['nbs'], {
      compareItems: state.nbs.compareItems?.some(ci => ci.id === nbs?.id)
        ? state.nbs.compareItems.filter(ci => ci.id !== nbs?.id)
        : (state.nbs.compareItems || []).concat(nbs)
    })
  }
};