import React, { forwardRef } from 'react';
import styled from 'styled-components';

// Components
import { Link } from 'react-router-dom';

const AnchorStyled = styled.a`
  transition: all 0.8s ease !important;
`;

export default forwardRef(function InternalExternalLink({
  to,
  children,
  ...props
}, externalRef) {
  const propsClean = ({ ...props });
  delete propsClean.url;
  delete propsClean.content;
  delete propsClean.iframe;

  return (
    <React.Fragment>
      {!!to && to.startsWith('/')
        ? (
          <Link
            {...propsClean}
            exact="true"
            to={to}
            ref={externalRef}
          >
            {children}
          </Link>
        ) : (
          <AnchorStyled
            {...propsClean}
            href={to}
            target="_blank"
            rel="noopener noreferrer"
            ref={externalRef}
          >
            {children}
          </AnchorStyled>
        )}
    </React.Fragment>
  )
});