import React from 'react';
import { StyleSheet, View, Text, Image as PdfImage } from '@react-pdf/renderer'

import imgOperandumLogo from '~/img/Operandum-Platform__Original_small.png';
import imgEu from '~/img/EU.jpg';

const Styles = StyleSheet.create({
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 60,
    right: 60,
    marginTop: 50,
    fontSize: 8,
    content: {
      display: 'flex',
      flexDirection: 'row',
      leftColumn: {
        flexBasis: 80,
        logo: {
          width: 80
        }
      },
      centerColumn: {
        flexBasis: 'auto',
        paddingRight: 105,
        paddingLeft: 5,
        paddingTop: 14
      },
      rightColumn: {
        flexBasis: 40,
        marginLeft: -100,
        paddingTop: 10,
        logo: {
          width: 40
        }
      }
    }
  }
});

export default function Footer() {
  return (
    <View fixed style={Styles.footer}>
      <View style={Styles.footer.content}>
        <View style={Styles.footer.content.leftColumn}>
          <PdfImage src={imgOperandumLogo} style={Styles.footer.content.leftColumn.logo} />
        </View>

        <Text style={Styles.footer.content.centerColumn}>
          Supported by the European Commission under the grant agreement No 776848 of European Union's Horizon 2020 research and innovation programme.
        </Text>

        <View style={Styles.footer.content.rightColumn}>
          <PdfImage src={imgEu} style={Styles.footer.content.rightColumn.logo} />
        </View>
      </View>
    </View>
  );
}