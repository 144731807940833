import React from 'react';
import { StyleSheet, Text } from '@react-pdf/renderer'

const Styles = StyleSheet.create({
  indicator: {
    single: {
      value: {
      }
    }
  }
});

export default function IndicatorSingleValue({
  indicator
}) {
  const key = !!indicator.suitableValues && indicator.suitableValues[0];

  const value = !!indicator.valueClasses && !!key && indicator.valueClasses.find(v => v.code === key);

  return (
    <Text style={Styles.indicator.single.value}>
      {value?.caption || ''}
    </Text>
  );
}