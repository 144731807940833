import { updateState } from "~/lib/Utils.Store";

export const initialState = {
  policy: {
    filters: {},
    nbs: {
      approach: null,
      hazard: null,
      habitat: null,
      intervention: null,
      policylevel: null,
      target: null,
      nbsType: null
    }
  }
};

export const symbiotes = {
  policy: {
    filtersChange: (state, filters) => updateState(state, ['policy'], { filters }),
    filtersReset: (state) => updateState(state, ['policy'], { filters: initialState.policy.filters }),
    nbs: {
      approachChange: (state, approach) => updateState(state, ['policy', 'nbs'], { approach }),
      hazardChange: (state, hazard) => updateState(state, ['policy', 'nbs'], { hazard }),
      habitatChange: (state, habitat) => updateState(state, ['policy', 'nbs'], { habitat }),
      interventionChange: (state, intervention) => updateState(state, ['policy', 'nbs'], { intervention }),
      policyLevelChange: (state, policylevel) => updateState(state, ['policy', 'nbs'], { policylevel }),
      targetChange: (state, target) => updateState(state, ['policy', 'nbs'], { target }),
      typeChange: (state, nbsType) => updateState(state, ['policy', 'nbs'], { nbsType })
    }
  }
};