import React from 'react';

// Components
import Modal from '~/components/modal/Modal';

export default function ModalUserProfile({
  profiles,
  currentProfile,
  show,
  onClose
}) {
  return (
    <Modal
      id="profile-modal"
      title="User profile"
      bodyClassName="bg-gwhite"
      show={show}
      onClose={onClose}
    >
      {!!profiles && !!profiles.length && (
        <div className="list-group list-group-flush text-gsecondary">
          {profiles.map((profile, indx) => {
            const selected = currentProfile?.id === profile.id;

            return (
              <div
                key={`profile-${indx}`}
                className={`list-group-item py-2 my-0 d-flex align-items-center ${selected ? 'text-gprimary' : ''}`}
              >

                <div className="mr-auto">
                  <p className="font-weight-bold mb-1">
                    {profile.name}
                  </p>

                  <p className="font-size-sm text-muted mb-0">
                    {profile.description}
                  </p>
                </div>

                {selected && (
                  <div className="badge badge-rounded-circle badge-primary ml-4">
                    <i className="fe fe-check"></i>
                  </div>
                )}

              </div>
            );
          })}
        </div>
      )}
    </Modal>
  );
}
