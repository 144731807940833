import React from 'react';

// Components
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

const Styles = StyleSheet.create({
  nbsType: {
    display: 'flex',
    flexDirection: 'column',
    name: {
      fontWeight: 'bold',
      marginRight: 10
    },
    description: {
      paddingRight: 40
    }
  }
});

export default function NbsType({
  nbsType
}) {
  return (
    <Paragraph title="Type">
      <View style={Styles.nbsType}>
        {nbsType.map(({ name, desc }, indx) => (
          <View key={`nbsType-${indx}`} style={{ display: 'flex', flexDirection: 'row' }}>
            <Text style={Styles.nbsType.name}>{name}</Text>
            <Text style={Styles.nbsType.description}>{desc || ''}</Text>
          </View>
        ))}
      </View>
    </Paragraph>
  );
}