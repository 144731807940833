import React, { useRef, useCallback, useState } from 'react';
import styled from 'styled-components';

import { useWindowEvent } from '~/lib/Effects';
import { scrollOnTopAnimated, noop } from '~/lib/Utils';


const ScrollOnTopButtonStyled = styled.button`
  z-index: 222;
  position: fixed;
  bottom: 0;
  right: 0;
  transition: opacity 0.5s ease, transform 0.25s ease;
  opacity: ${ /* sc-value */ ({ show }) => show ? '1' : '0'} !important;
  visibility: ${ /* sc-value */ ({ show }) => show ? 'visible' : 'hidden'} !important;
`;

export default function ScrollOnTopButton({
  distance = 200,
  opacity = 1,
  btnColor = 'primary-soft',
  label,
  marginRight = 5,
  onScrollDone = noop
}) {

  const buttonRef = useRef(null);
  const [show, setShow] = useState(false);

  const handleButtonClick = useCallback(() => {
    scrollOnTopAnimated(window, {
      callback: () => {
        onScrollDone();
        !!buttonRef.current && buttonRef.current.blur();
      }
    });
  }, [buttonRef, onScrollDone]);

  const handleWindowScroll = useCallback(() => {
    const pageHeight = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight
      || 0;

    const browserHeight = document.body.getBoundingClientRect().height;

    setShow(window.scrollY + pageHeight > browserHeight - distance);
  }, [distance]);

  useWindowEvent('scroll', handleWindowScroll);

  return (
    <ScrollOnTopButtonStyled
      ref={buttonRef}
      show={show}
      className={`btn btn-lg btn-${!!label ? 'pill' : 'rounded-circle'} btn-${btnColor} lift mr-${marginRight} mb-5`}
      onClick={handleButtonClick}
    >
      {!!label
        ? (<div>{label}</div>)
        : (<img src="/assets/img/icons/duotone-icons/Navigation/Up-2.svg" alt="On top" width="48" />)
      }
    </ScrollOnTopButtonStyled>
  );
}