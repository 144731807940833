import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useStore, actions } from '~/store/StoreProvider';
import { useOwa } from '~/owa/OpenWebAnalyticsProvider';
import { HelpProvider } from '~/components/help/HelpProvider';

// Components
import NavBar from '../components/App/NavBar2';
import HelpButton from '~/components/help/HelpButton';
import PermissionedView from './PermissionedView';

export default function MainView({
  title = 'GeoIKP',
  helpContext,
  children
}) {
  const tracker = useOwa();
  const location = useLocation();

  const { state, dispatch } = useStore();

  const handleProfileSelect = useCallback((profile) => {
    dispatch(actions.app.currentProfileChange(profile));  // this will cause 
  }, [dispatch]);

  useEffect(() => {
    if (!!tracker && !!location) {
      document.title = title;

      tracker.trackPageView(location.pathname);
    }
  }, [title, location, tracker]);

  return (
    <PermissionedView>
      <HelpProvider helpContext={helpContext}>
        <div className="flex-grow-0">
          <NavBar
            user={state.user.data}
            profiles={state.profiles}
            currentProfile={state.currentProfile}
            onProfileSelect={handleProfileSelect}
          />
        </div>

        <div className="flex-grow-1 h-100">
          {children}
        </div>

        <HelpButton />
      </HelpProvider>
    </PermissionedView>
  );
}