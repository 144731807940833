import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

import logo from '~/img/Operandum-Platform__Original.png';

const TitleStyled = styled.div`
  font-size: 0.7rem;
  font-weight: 200;
  transform: scaleY(1.3);
`;

export default forwardRef(function Brand({
  url,
  content,
  props
}, externalRef) {

  return (
    <NavLink
      {...props}
      exact={true}
      className="navbar-brand position-relative"
      to={!url || (!!url && url.startsWith('/')) ? '/home' : url}
      ref={externalRef}
      style={{ minWidth: '15rem' }}
    >
      <img src={logo} className="gnavbar-brand-img mr-3" alt={content || 'GeoIKP'} />
    </NavLink>
  );
});
