import React from 'react';
import DOMPurify from 'dompurify';

// Components
import { Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

export default function Size({
  size,
  sizeShort
}) {
  return (
    <Paragraph title="Size">
      <Text>{DOMPurify.sanitize(size || '') || sizeShort}</Text>
    </Paragraph>
  );
}