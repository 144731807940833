import axios from 'axios';

import { objectToCamelCase, objectToSnakeCase } from './Utils';

export function createApi(history) {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_URLBACKEND}${process.env.REACT_APP_VERAPI}`, //${process.env.REACT_APP_VERAPI}
  });

  instance.defaults.xsrfHeaderName = 'Authorization';

  instance.defaults.headers.common['Accept'] = 'application/json';
  instance.defaults.headers.post['Content-Type'] = 'application/json; charset=utf8';
  instance.defaults.headers.common['Accept-Language'] = localStorage.getItem('lang') || 'en';

  //instance.defaults.headers.common['X-ApiKey'] = localStorage.getItem('api_key');
  const token = localStorage.getItem('user.access_token');

  if (!!token) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  }

  instance.defaults.validateStatus = status => status >= 200 && status < 300; // actually, this is a default range

  instance.defaults.timeout = 60000;

  instance.interceptors.request.use(
    config => {
      if (config.method.toLowerCase() === 'post') {
        return ({
          ...config,
          data: JSON.stringify(objectToSnakeCase(config.data))
        });
      } else {
        return config;
      }
    },
    error => {
      return Promise.reject(error); // default
    }
  );

  instance.interceptors.response.use(
    response => {
      const resp = {
        ...response,
        data: objectToCamelCase(response.data),
      };

      return resp;
    },
    error => {
      if (error && error.response) {
        switch (error.response.status) {
          case 401:
            if (history.location.pathname !== '/login') {
              history.replace('/login');
            }
            break;
          case 403:
            if (history.location.pathname !== '/notauthorized') {
              history.replace('/notauthorized');
            }
            break;
          default:
            break;
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
}

export function authorizeUrl(url, user, apiKey) {
  if (!!user && !!apiKey) {
    return `${url}&username=${user.username}&api_key=${apiKey}`;
  } else {
    return url;
  }
}
