import React from 'react';
import DOMPurify from 'dompurify';

// Components
import { Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

export default function Implementation({
  implementationNotes,
  implementationTime
}) {
  return (
    <Paragraph title="Implementation">
      <Text>{DOMPurify.sanitize(implementationNotes || '')}</Text>
      <Text>{implementationTime}</Text>
    </Paragraph>
  );
}