import React, { useMemo } from 'react';

// Components
import TabIndicatorCard from './Card/TabIndicatorCard';
import IndicatorList from './Indicator/IndicatorList';
import IndicatorSingleValue from './Indicator/IndicatorSingleValue';

const IconFolder = '/assets/img/icons/toolkit/indicators';

function getIndicatorIcon(name, selected) {
  switch (name.toLowerCase()) {
    case 'land cover': return `${IconFolder}/globe-solid${selected ? '-white' : ''}.svg`;
    case 'leaf area index': return `${IconFolder}/leaf-solid${selected ? '-white' : ''}.svg`;
    case 'slope': return `${IconFolder}/chart-area-solid${selected ? '-white' : ''}.svg`;
    case 'soil sealing': return `${IconFolder}/water-solid${selected ? '-white' : ''}.svg`;
    default: return `${IconFolder}/chart-bar-solid${selected ? '-white' : ''}.svg`;
  }
}

export const IndicatorPresentationType = ({
  list: 'list',
  singleValue: 'single_value',
  //
});

export default function IndicatorCard({
  indicator,
  isSelected,
  onIndicatorSelect
}) {
  const content = useMemo(() => {
    if (!!indicator) {
      switch (indicator.presentationType) {
        case IndicatorPresentationType.list: return (<IndicatorList indicator={indicator} />);
        case IndicatorPresentationType.singleValue: return (<IndicatorSingleValue indicator={indicator} />);
        default: return (null);
      }
    }
  }, [indicator]);

  return (
    <TabIndicatorCard
      hasHeader={true}
      title={indicator.attGroup}
      icon={getIndicatorIcon(indicator.name, isSelected)}
      className="mr-3 mb-3 flex-grow-1"
      style={{ minWidth: `${indicator.widthValue}${indicator.widthUom}`, minHeight: '10rem' }}
      selectable={!!indicator?.sourceLayer?.length && !!indicator?.attGroup}
      isSelected={isSelected}
      onCardSelect={onIndicatorSelect}
    >
      {content}
    </TabIndicatorCard>
  );
}