import React from 'react';
import DOMPurify from 'dompurify';

// Components
import { Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

export default function Maintenance({
  nbsMaintenance
}) {
  return (
    <Paragraph title="NBS Maintenance">
      <Text>{DOMPurify.sanitize(nbsMaintenance || '')}</Text>
    </Paragraph>
  );
}