import React, { useEffect } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useStore, actions } from '~/store/StoreProvider';

// Components
import PermissionedView from '~/modules/main/views/PermissionedView';

export default function ProfileSelectorRedirect() {
  const { state, dispatch } = useStore();

  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    const profileId = pathname.startsWith('/scientist/')
      ? 1
      : pathname.startsWith('/citizen/')
        ? 2
        : pathname.startsWith('/business/')
          ? 3
          : pathname.startsWith('/policy/')
            ? 4
            : pathname.startsWith('/association/')
              ? 5
              : pathname.startsWith('/newsmedia/')
                ? 6
                : -1;

    if (!!state?.profiles) {
      const profile = state.profiles.find(p => p.id === profileId);

      if (!!profile && !!state.currentProfile) {
        dispatch(actions.app.currentProfileChange(profile));
        history.replace(`/${pathname.split('/').skip(2).join('/')}`);
      }
    }
  }, [pathname, state, history, dispatch]);

  return (
    <PermissionedView />
  );
}