import React from 'react';
import styled from 'styled-components';

import { noop } from '~/lib/Utils';
import { useStopPreventCallback } from '~/lib/Effects';

const TabHeaderStyled = styled.div`
  letter-spacing: 0.1rem;
  box-shadow: 4px 3px 6px #00000029;
  max-height: 3.5rem;
  transition: background-color 0.8s ease;
`;

const TabBodyStyled = styled.div`
  ${({ withHeader }) => !withHeader && 'border-radius: 0.75rem !important'}
`;

export default function TabIndicatorCard({
  title,
  icon,
  iconWidth = '2rem',
  className = '',
  style = {},
  hasHeader,
  children,
  selectable,
  isSelected,
  onCardSelect
}) {
  const handleCardSelect = useStopPreventCallback(selectable ? onCardSelect : noop, title);

  return (
    <div className={`card card-g bg-transparent ${className}`} style={{ width: 'auto', ...style }}>
      {!!hasHeader && (
        <TabHeaderStyled
          className={`card-header bg-g${isSelected ? 'primary-alt' : 'white'} d-flex flex-row text-nowrap text-uppercase text-g${isSelected ? 'white' : 'secondary-alt2'} font-weight-bold zindex-1 ${selectable ? 'pointable' : ''}`}
          onClick={handleCardSelect}
        >
          <img src={icon} alt=".." className="mr-2" style={{ width: iconWidth }} />
          <div className="mr-5">{title}</div>
          {!!isSelected && (
            <img src="/assets/img/icons/toolkit/layer-group-solid-white.svg" alt=".." className="ml-auto mr-2" style={{ width: '2rem' }} />
          )}
          {!isSelected && selectable && (
            <img src="/assets/img/icons/toolkit/layer-group-solid.svg" alt=".." className="ml-auto mr-2" style={{ width: '2rem' }} />
          )}
        </TabHeaderStyled>
      )}

      <TabBodyStyled className="card-body h-100 bg-gsecondary-desat-alt p-0" withHeader={hasHeader}>
        {children}
      </TabBodyStyled>
    </div>
  )
}