import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

// Components
import Brand from './Navbar/Brand';
import Toggler from './Navbar/Toggler';
import MainMenu from './Navbar/MainMenu';
import ProfileComponent, { ProfilePermissionType } from '~/components/profile/ProfileComponent';
import ProfileSelectModal from './Navbar/ProfileSelectModal';


// const ProfileSelectStyled = styled.img`
//   border-top-left-radius: 50%;
//   border-bottom-left-radius: 50%;
//   border: solid 3px var(--white);
//   background-color: var(--warning);
//   padding-right: 0.75rem !important;
//   margin-right: -10px !important;
//   transition: margin-right 0.35s ease, box-shadow .25s ease, transform .25s ease !important;

//   &:hover {
//     margin-right: -3px !important;
//   }
// `;

export default function Navbar({
  user,
  profiles,
  currentProfile,
  onProfileSelect
}) {

  const [showProfileModal, setShowProfileModal] = useState(false);

  const handleProfileSelectOpen = useCallback(() => {
    setShowProfileModal(true);
  }, []);

  const handleProfileSelectClose = useCallback(() => {
    setShowProfileModal(false);
  }, []);

  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-white py-4">
        <div className="container-fluid">
          <ProfileComponent name="home.navbar.home" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
            <Brand />
          </ProfileComponent>

          <Toggler />

          <MainMenu
            user={user}
            profiles={profiles}
            currentProfile={currentProfile}
            onProfileSelect={onProfileSelect}
            onProfileSelectOpen={handleProfileSelectOpen}
          />
        </div>
      </nav>

      {!user && (
        <React.Fragment>
          {/*<div className="position-absolute top-0 right-0 pt-12" style={{ zIndex: 1000 }}>
            <ProfileSelectStyled
              src="/assets/img/icons/profile/ChangeUserProfile_icon.svg"
              width="64"
              className="mt-n3 shadow-dark pointable"
              alt="Select profile"
              title="Select profile"
              onClick={handleProfileSelectOpen}
            />
      </div>*/}

          <ProfileSelectModal
            show={showProfileModal}
            profiles={profiles}
            currentProfile={currentProfile}
            onProfileSelect={onProfileSelect}
            onClose={handleProfileSelectClose}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
