import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { formatString } from '~/lib/Utils';

// Components
import InternalExternalLink from '~/components/link/InternalExternalLink';

const AvatarBadgeStyled = styled.div`
  white-space: pre-wrap !important;
  
  background-color: #fbaf40 !important;
  text-decoration: none !important;

  &a:hover {
    color: var(--white) !important;
  }
`;

export default forwardRef(function Crowdsourcing({
  url,
  label,
  iframe,
  content,
  user,
  profile
}, externalRef) {
  const token = !!user && user.token;
  const upid = !!profile && profile.id;

  const to = !!url && formatString(url, { token, upid }, { before: '{', after: '}' });

  return (
    <React.Fragment>
      <AvatarBadgeStyled
        className="d-none d-lg-inline-block dropdown badge badge-rounded-circle badge-xl text-light badge-float-outside position-absolute z-index1"
        ref={externalRef}
      >
        <div
          className="text-white dropdown-toggle"
          id="review_nbs"

          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fe fe-eye font-size-lg" />
        </div>

        <div className="dropdown-menu shadow-dark bg-gprimary-desat-alt2 mt-5" aria-labelledby="review_nbs" style={{ left: '-4rem' }}>
          <div className="list-group list-group-flush mx-5 bg-gprimary-desat-alt2">
            <div className="list-group-item d-flex font-weight-normal align-items-center bg-gprimary-desat-alt2">
              <div className="icon icon-sm text-primary">
                <img src="/assets/img/icons/duotone-icons/General/Visible.svg" alt="..." width="36" />
              </div>
              <div className="ml-4">
                <h6 className="font-weight-bold text-uppercase text-primary mb-0">
                  {content || label}
                </h6>
                <p className="font-size-sm text-gray-700 mb-0">
                  <span className="small" style={{ lineHeight: '1.6rem' }}>New NBS case studies are pending to be reviewed by you.</span>
                  <InternalExternalLink
                    to={!!iframe ? '/nbs/review' : to}
                    role="button"
                    className="btn btn-sm btn-gwarning-desat mt-2 lift"
                  >
                    {content || label}
                  </InternalExternalLink>
                </p>
              </div>
            </div>
          </div>
        </div>
      </AvatarBadgeStyled>

      <InternalExternalLink
        to={!!iframe ? '/nbs/review' : to}
        role="button"
        className="d-flex d-lg-none btn btn-sm btn-pill btn-warning"
      >
        {content || label}
      </InternalExternalLink>
    </React.Fragment>
  );
});