import React from 'react';

// Components
import { StyleSheet, View, Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

const Styles = StyleSheet.create({
  approach: {
    display: 'flex',
    flexDirection: 'column'
  }
});

export default function Approach({
  approach
}) {
  return (
    <Paragraph title="Approach">
      <View style={Styles.approach}>
        {approach?.map(({ name, desc }, indx) => (
          <Text key={`approach-${indx}`}>
            {name} {!!desc ? `(${desc})` : ''}
          </Text>
        ))}
      </View>
    </Paragraph>
  );
}