import React, { useRef, useState, useCallback } from 'react';
import styled from 'styled-components';

import { scrollOnePageAnimated, noop } from '~/lib/Utils';
import { useBrowserWindowChanges } from '~/lib/Effects';

const ScrollViewMoreButtonStyled = styled.button`
  z-index: 222;
  position: fixed;
  bottom: 0;
  right: 0;
  transition: opacity 0.5s ease, transform 0.25s ease;
  opacity: ${ /* sc-value */ ({ show }) => show ? '1' : '0'} !important;
  visibility: ${ /* sc-value */ ({ show }) => show ? 'visible' : 'hidden'} !important;
`;


export default function ScrollViewMoreButton({
  distance = 100,
  label,
  btnColor = 'primary-soft',
  onScrollDone = noop
}) {

  const buttonRef = useRef(null);
  const [show, setShow] = useState(false);

  const handleButtonClick = useCallback(() => {
    scrollOnePageAnimated({
      callback: () => {
        onScrollDone();
        !!buttonRef.current && buttonRef.current.blur();
      }
    });
  }, [buttonRef, onScrollDone]);

  const handleWindowScroll = useCallback(() => {
    const pageHeight = window.innerHeight
      || document.documentElement.clientHeight
      || document.body.clientHeight
      || 0;

    const browserHeight = document.body.getBoundingClientRect().height;

    setShow(window.scrollY + pageHeight < browserHeight - distance);
  }, [distance]);

  useBrowserWindowChanges(handleWindowScroll);


  return (
    <ScrollViewMoreButtonStyled
      ref={buttonRef}
      show={show}
      className={`btn ${!!label ? '' : 'btn-lg'} btn-${!!label ? 'pill' : 'rounded-circle'} btn-${btnColor} shadow-dark lift mr-5 mb-5`}
      onClick={handleButtonClick}
    >
      {!!label
        ? (<div>{label}</div>)
        : (<img src="/assets/img/icons/duotone-icons/Navigation/Up-2.svg" alt="On top" width="48" />)
      }
    </ScrollViewMoreButtonStyled>
  )
}