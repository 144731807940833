import React, { useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { useStore, actions } from '~/store/StoreProvider';
import { useNotifications, NotificationType } from '~/lib/Effects.Notify';

import { validation } from '../model/validation';

import {
  usePostUserLogIn
} from '../api';

// Components
import { Link } from 'react-router-dom';
import ModalUserProfile from '../components/modals/UserProfileModal';
import AuthContainer from '../components/AuthContainer';
import ProfileSelector from '~/modules/main/components/App/Navbar/MainMenu/ProfileSelector';
import ProfileSelectModal from '~/modules/main/components/App/Navbar/ProfileSelectModal';

const SignInTitleStyled = styled.div`
  font-size: 3rem;
  font-family: "Raleway Extrabold";
`;

export default function LogInPage() {
  const { state, dispatch } = useStore();
  const notify = useNotifications();
  const { register, handleSubmit, errors, formState } = useForm();

  const getUsePostUserLogIn = usePostUserLogIn();

  const handleLogIn = useCallback(async ({ email, password }) => {
    const result = await getUsePostUserLogIn({
      data: {
        email,
        pwd: password
      }
    });

    if (!!result) {
      if (!!result.error) {
        notify({
          type: NotificationType.error,
          message: result.error,
          title: 'Login failed'
        });
      } else if (!!result.response) {
        if (!result.response.token) {
          notify({
            type: NotificationType.error,
            message: result.response.error || result.response.result,
            title: 'Authentication failed'
          });
        } else {
          dispatch(actions.user.tokenChange(result.response.token));

          dispatch(actions.user.dataChange(result.response.user));
          dispatch(actions.app.permissionsChange(result.response.permissions));
          dispatch(actions.app.currentProfileChange(result.response.userProfile));
        }
      }
    }
  }, [getUsePostUserLogIn, notify, dispatch]);

  const handleLogOut = useCallback(() => {
    dispatch(actions.user.tokenClear());

    dispatch(actions.user.dataClear());
    dispatch(actions.app.permissionsClear());
    // do not clear a current profile, because everythign supposes that it is selected on Welcome page
    // let's keep it from user's data for a case
  }, [dispatch]);


  const [viewProfile, setViewProfile] = useState(false);

  const handleUserProfileShow = useCallback(() => {
    setViewProfile(true);
  }, []);

  const handleUserProfileHide = useCallback(() => {
    setViewProfile(false);
  }, []);

  const [showProfileModal, setShowProfileModal] = useState(false);

  const handleProfileSelectOpen = useCallback(() => {
    setShowProfileModal(true);
  }, []);

  const handleProfileSelectClose = useCallback(() => {
    setShowProfileModal(false);
  }, []);

  const handleProfileSelect = useCallback((profile) => {
    dispatch(actions.app.currentProfileChange(profile));

    if (state.user?.data?.userProfileId !== profile.id) {
      notify({
        type: NotificationType.success,
        message: `Your profile was temporary switched to ${profile.name}`,
        title: 'Profile changed'
      });
    }
  }, [state, dispatch, notify]);

  return (
    <AuthContainer backgroundImageSrc="/assets/img/covers/cover-signin.jpg">

      <ProfileSelectModal
        show={showProfileModal}
        profiles={state.profiles}
        currentProfile={state.currentProfile}
        user={state.user?.data}
        onProfileSelect={handleProfileSelect}
        onClose={handleProfileSelectClose}
      />

      {!!state.user.data
        ? (
          <React.Fragment>
            <div className="text-center">
              <h2 className="text-success">Welcome{!!state.user.data.displayName ? `, ${state.user.data.displayName}` : ''}!</h2>
              <h4>{state.user.data.username}</h4>
              <div className="d-flex flex-column align-items-center mb-3">
                <div className="mb-3 text-gsecondary">You can temporary try a different profile</div>
                <ProfileSelector
                  className="d-flex mb-3"
                  user={state.user?.data}
                  profile={state.currentProfile}
                  onProfileSelectOpen={handleProfileSelectOpen}
                />
              </div>
              <Link to="/home" className="lift-g text-uppercase">start navigating</Link>
              <hr />
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <SignInTitleStyled className="mb-0 font-weight-bold text-gsuccess">
              Sign in
            </SignInTitleStyled>
            <p className="my-6 w-75 text-muted small mx-5 text-center">
              Gain access to additional functionalities of GeoIKP available to the registered users.
            </p>
          </React.Fragment>
        )}

      {!state.user.data && (
        <form
          className="mb-6 text-center"
          noValidate={true}
          style={{ width: '60%' }}
          onSubmit={handleSubmit(handleLogIn)}

        >
          <div className="form-label-group mx-0">
            <input
              id="email"
              type="email"
              name="email"
              className={`form-control form-control-flush ${formState.isSubmitted ? !!errors.email ? 'is-invalid' : 'is-valid' : ''}`}
              placeholder="name@address.com"
              ref={register(validation.login.email)}
            />
            <label htmlFor="email">Email Address</label>
          </div>
          <div className="form-label-group mx-0 mb-5">
            <input
              id="password"
              type="password"
              name="password"
              className={`form-control form-control-flush ${formState.isSubmitted ? !!errors.password ? 'is-invalid' : 'is-valid' : ''}`}
              placeholder="Enter your password"
              ref={register(validation.login.password)}
            />
            <label htmlFor="password">Password</label>
          </div>
          <button
            type="submit"
            className="btn btn-g btn-block btn-gprimary btn-pill text-uppercase lift lift-g"
          >
            Sign in
          </button>
        </form>
      )}

      {!!state.user.data
        ? (
          <div>
            <button
              className="btn btn-g btn-block btn-gprimary btn-pill text-uppercase lift lift-g"
              onClick={handleLogOut}
            >
              Logout
            </button>
          </div>
        ) : (
          <p className="mb-0 font-size-sm text-muted">
            <span className="small">Don&quot;t have an account yet?</span> <Link to="/signup" style={{ transition: 'all 0.8s ease' }}>Sign up</Link>.<br />
            <span className="mb-6 text-muted">
              <a href="#/" onClick={handleUserProfileShow} style={{ transition: 'all 0.8s ease' }}>
                What are OPERANDUM user profiles?
              </a>
            </span>

            <ModalUserProfile
              profiles={state.profiles}
              currentProfile={state.currentProfile}
              show={viewProfile}
              onClose={handleUserProfileHide}
            />
          </p>
        )}

    </AuthContainer>
  );
}
