import React from 'react';
import { StyleSheet, View, Text } from '@react-pdf/renderer'

const Styles = StyleSheet.create({
  paragraph: {
    marginBottom: 10,
    marginLeft: 10,
    minHeight: 20,
    position: 'relative',
    caption: {
      position: 'absolute',
      left: 0,
      fontSize: 11,
      fontWeight: 'bold',
      maxWidth: 100,
      width: 100
    },
    content: {
      width: '100%',
      marginLeft: 20,
      paddingLeft: 100
    }
  }
});

export default function Paragraph({
  title,
  children
}) {
  return (
    <View style={Styles.paragraph}>
      <Text style={Styles.paragraph.caption}>{title}</Text>
      <View style={Styles.paragraph.content}>
        {children}
      </View>
    </View>
  );
}