import React from 'react';
import styled from 'styled-components';

// Components
import { Link } from 'react-router-dom';

import imgEU from '~/img/EU.jpg';
import logo from '~/img/Operandum-Platform__Vertical-version_monochromatic_no-subtitle.png';

const BackgroundStyled = styled.div`
position: absolute;
top:0;
left:0;
  background-image: url(${/* sc-value */ ({ src }) => src});
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center;
`;

const EULogoStyled = styled.img`
  width: 2rem;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(255, 255, 255);
`;

const OperandumLogoStyled = styled.img`
  width: 20rem;
  margin-left: -5rem;
  margin-right: -5rem;
`;


export default function NotFoundPage({ noLogo = false }) {

  return (
    <BackgroundStyled className="row align-items-center w-100 vh-100 p-0 m-0 overflow-hidden" src="/assets/img/covers/cover-welcome.jpg">
      <div className="row m-0 w-100 vh-100">
        <div className="col-5 p-0" style={{ backgroundColor: 'rgba(134,154,184, 0.7)' }}>
          <div className="h-100 d-flex flex-column justify-content-around ml-8">
            <div />
            <div>
              <div className="text-center display-3 text-white">This resource is not yet available</div>
              <div className="text-center h3 text-white font-weight-bold mt-8">We are sorry but this feature is not yet implemented. We are working hard on making it available as soon as possible. Please visit this site soon.</div>
            </div>
            <div className="text-center text-nowrap">
              <Link to="/home" style={{ zIndex: 10 }}><div className="btn btn-pill btn-primary lift my-auto px-8">Take me back to the home page</div></Link>
            </div>
          </div>
        </div>
        <div className="col-7 p-0 vh-100">
          <div className="shape shape-left shape-fluid-y svg-shim text-white">
            <svg viewBox="0 0 500 1544" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0h500v386l-250 772v386H0V0z" fill="#869AB8" opacity="0.7"></path>
            </svg>
          </div>
        </div>
      </div>

      {!noLogo && (
        <div className="position-absolute top-0 right-0 p-8">
          <OperandumLogoStyled src={logo} alt="OPERANDUM" />
        </div>
      )}

    </BackgroundStyled >
  );
}