import React from 'react';
import DOMPurify from 'dompurify';

// Components
import { Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

export default function Modelling({
  modelling
}) {
  return (
    <Paragraph title="Modeling">
      <Text>{DOMPurify.sanitize(modelling || '')}</Text>
    </Paragraph>
  );
}