import React from 'react';
import DOMPurify from 'dompurify';

// Components
import { Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

export default function GreySolution({
  greySolution
}) {
  return (
    <Paragraph title="Grey Solution">
      <Text>{DOMPurify.sanitize(greySolution || '')}</Text>
    </Paragraph>
  );
}