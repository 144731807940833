import React, { useEffect } from 'react';

import { useStore, actions } from '~/store/StoreProvider';

import {
  useFetchUserProfiles,
  useFetchProfilePermissions,
  useFetchStoredToken
} from '~/modules/user/api';

// Components
import { PermissionsProvider } from '~/store/PermissionsProvider';

export default function PermissionedView({ children }) {
  const { state, dispatch } = useStore();

  // state.currentProfile is changed in two places: 
  // when anonymous user selects a profile (at Welcome page or at Select Profile modal),
  // and when user is logged in
  // state.user.data is filled in after a log in
  // state.currentProfile is loaded from localStorage initially, and is put into it when is changed
  //
  // useFetchProfilePermissions returns nothing if there is no profile, or when user is logged in
  // in this case state.permissions will be used for PermissionsProvider below
  //
  // state.permissions is filled in after user logs in, 
  // and is an alternative to useFetchProfilePermissions(state.currentProfile) response
  const [{ response: permissionList }] = useFetchProfilePermissions(state.currentProfile, state.user.data);

  const [{ response: storedToken }] = useFetchStoredToken(state.user.token, state.user.data);

  const getFetchUserProfiles = useFetchUserProfiles();

  useEffect(() => {
    const fetchProfiles = async () => {
      const result = await getFetchUserProfiles();

      if (!!result && !result.error && !!result.response) {
        dispatch(actions.app.profilesChange(result.response));
      }
    }

    fetchProfiles();
  }, [getFetchUserProfiles, dispatch]);

  useEffect(() => {
    if (!!storedToken) {
      if (!!storedToken.error) {
        dispatch(actions.user.tokenClear());
        dispatch(actions.user.dataClear());
        dispatch(actions.app.permissionsClear());
      } else {
        dispatch(actions.user.dataChange(storedToken.user));
        dispatch(actions.app.permissionsChange(storedToken.permissions));
        dispatch(actions.app.currentProfileChange(storedToken.userProfile));
      }
    }
  }, [storedToken, dispatch]);

  return (
    <PermissionsProvider permissions={permissionList || state.permissions}>
      {children}
    </PermissionsProvider>
  );
}