import React from 'react';

// Components
import { StyleSheet, View, Text, Link } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

const Styles = StyleSheet.create({
  policy: {
    marginBottom: 3,
    title: {
      fontStyle: 'italic'
    }
  }
});

export default function Policies({
  relatedPolicy
}) {
  return (
    <Paragraph title="NBS Related Policies">
      {relatedPolicy?.map((policy, indx) => (
        <View key={`policy-${indx}`} style={Styles.policy}>
          <Text style={Styles.policy.title}>
            {policy.title}
          </Text>
          <Text>
            Source: <Link src={policy.url}>{policy.url}</Link>
          </Text>
        </View>
      ))}
    </Paragraph>
  );
}