import React, { useCallback } from 'react';
import styled from 'styled-components';

import { openUrlNoOpener } from '~/lib/Utils';

import { useHelp } from './HelpProvider';

const HelpButtonStyled = styled.button`
  z-index: 222;
  position: fixed;
  bottom: 0;
  right: 0;
  transition: opacity 0.5s ease, transform 0.25s ease;
  opacity: ${ /* sc-value */ ({ show }) => show ? '1' : '0'} !important;
  visibility: ${ /* sc-value */ ({ show }) => show ? 'visible' : 'hidden'} !important;
`;


export default function HelpButton({
  label,
  btnColor = 'gprimary-alt2',
  color = 'gwhite'
}) {
  const help = useHelp();

  const handleButtonClick = useCallback(() => {
    const url = help.getHelpUrl();

    url && openUrlNoOpener(url);
  }, [help]);

  return (
    <HelpButtonStyled
      show={!!help.helpContext}
      className={`btn btn-g ${!!label ? '' : 'btn-lg'} btn-${!!label ? 'pill' : 'rounded-circle'} btn-${btnColor} shadow-dark lift lift-g mr-5 mb-5 p-2 text-${color}`}
      onClick={handleButtonClick}
    >
      {!!label
        ? (<div>{label}</div>)
        : (<i className="fe fe-help-circle" style={{ fontSize: '2.5rem' }} />)
      }
    </HelpButtonStyled>
  )
}