import React from 'react';
import styled from 'styled-components';

const IndicatorTitleStyled = styled.div`
  font-size: 2.5rem;
  line-height: 2.75rem;
`;

export default function IndicatorSingleValue({
  indicator
}) {
  const key = !!indicator.suitableValues && indicator.suitableValues[0];

  const value = !!indicator.valueClasses && !!key && indicator.valueClasses.find(v => v.code === key);

  return (
    <div className="h-100 p-5 d-flex flex-column justify-content-center align-items-center">
      {!!value && !!value.caption && (
        <IndicatorTitleStyled className="mt-n5 text-gwarning-alt text-center font-weight-bold">
          {value.caption}
        </IndicatorTitleStyled>
      )}
    </div>
  );
}