import { useGetCallback } from 'react-cached-callback';

import { stopPreventEvent, eventTargetBlur } from '~/lib/Utils';

export const ProfileIconUrls = ({
  1: '/assets/img/icons/profile/profile_scientist.svg',
  2: '/assets/img/icons/profile/profile_citizen.svg',
  3: '/assets/img/icons/profile/profile_business.svg',
  4: '/assets/img/icons/profile/profile_policy.svg',
  5: '/assets/img/icons/profile/profile_association.svg',
  6: '/assets/img/icons/profile/profile_media.svg'
});

export const ProfileUrls = ({
  1: 'scientist',
  2: 'citizen',
  3: 'business',
  4: 'policymaker',
  5: 'association',
  6: 'newsmedia'
});

export function useProfileSelect(onProfileSelect) {
  return useGetCallback(
    (profile) =>
      (e) => {
        stopPreventEvent(e);
        eventTargetBlur(e);

        !!onProfileSelect && onProfileSelect(profile);
      },
    profile => profile.id,
    [onProfileSelect]
  );
}