import React, { forwardRef } from 'react';
import styled from 'styled-components';

// Components
import { Link } from 'react-router-dom';

const AvatarStyled = styled.div`
  @media(max-width: 991.98px) {
    width: 100% !important;
  }
`;

const UserAvatarContainerSAtyled = styled.div`
  border: 2px solid var(--gprimary-desat) !important;
  margin-top: -6px;
  margin-bottom: -6px;

  @media(max-width: 991.98px){
    margin-bottom: 2rem;
  }
`;

const AvatarImageStyled = styled.img`
  opacity: 0.8;
  transform: scale(0.8);
`;

const AvatarNameStyled = styled.span`
  font-size: 2rem;
  top: 0.25rem;
  opacity: 0.8;
  transform: scale(0.8);
`;

// TODO: use user.avatar when it is avaialble
export default forwardRef(function UserAvatar({
  user,
  badge
}, externalRef) {
  return (
    <UserAvatarContainerSAtyled
      ref={externalRef}
      className="btn btn-rounded-circle btn-primary-soft btn-outline btn-sm ml-5 pl-5 pr-2 pr-lg-5"
      style={{

      }}
    >
      <AvatarStyled className="avatar avatar-lg d-flex flex-row justify-content-between">
        <Link
          to="/login"
          className="text-decoration-none ml-n1"
        >
          {!!user ?
            !!user.avatar || !user.displayName
              ? (
                <AvatarImageStyled
                  src="/assets/img/icons/duotone-icons/General/User.svg"
                  alt="..."
                  className="avatar-img rounded-circle"
                />)
              : (<AvatarNameStyled
                className="position-relative text-capitalize"
              >
                {user.displayName[0]}
              </AvatarNameStyled>)
            : null
          }
        </Link>
        {!!badge && badge}
      </AvatarStyled>
    </UserAvatarContainerSAtyled>
  );
});