import React, { useCallback, useContext } from 'react';

import { Tabs } from './TabsProvider';

export default function TabDetailsActivator({
  className = '',
  tabDetails,
  children
}) {
  const context = useContext(Tabs);

  const handleTabDetailsShow = useCallback(() => {
    context.showDetailsChange(...tabDetails);
  }, [tabDetails, context]);

  return (
    <div className={className} onClick={handleTabDetailsShow}>
      {children}
    </div>
  )
}