import { updateState } from "~/lib/Utils.Store";

export const initialState = {
  loading: 0,
  language: localStorage.getItem('lang') || 'en',
  profiles: [],
  currentProfile: JSON.parse(localStorage.getItem('profile')) || null,
  permissions: []
};

export const symbiotes = {
  loading: {
    start: (state) => updateState(state, [], { loading: state.loading + 1 }),
    stop: (state) => updateState(state, [], { loading: state.loading - Math.sign(state.loading) })
  },
  app: {
    setLanguage: (state, language) => {
      localStorage.setItem('lang', language);

      return updateState(state, [], { language });
    },
    profilesChange: (state, profiles) => {
      let newState = updateState(state, [], { profiles });

      if (!state.currentProfile && !!profiles) {
        const currentProfile = profiles.find(p => p.defaultUp);

        if (!!currentProfile) {
          localStorage.setItem('profile', (!!currentProfile && JSON.stringify(currentProfile)) || null);

          newState = updateState(newState, [], { currentProfile });
        }
      }
      return newState;
    },
    currentProfileChange: (state, currentProfile) => {
      localStorage.setItem('profile', (!!currentProfile && JSON.stringify(currentProfile)) || null);

      return updateState(state, [], { currentProfile });
    },
    currentProfileClear: (state) => updateState(state, [], { currentProfile: initialState.currentProfile }),
    permissionsChange: (state, permissions) => updateState(state, [], { permissions }),
    permissionsClear: (state) => updateState(state, [], { permissions: initialState.permissions })
  }
};