import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useStore, actions } from '~/store/StoreProvider';

import { useOwa } from '~/owa/OpenWebAnalyticsProvider';

// Components
import NavBar from '../components/App/NavBar';
import ScrollViewMoreButton from '~/components/scroll/ScrollViewMoreButton';
import PermissionedView from './PermissionedView';

export default function MainView({
  title = 'GeoIKP',
  children
}) {

  const tracker = useOwa();
  const location = useLocation();

  const { state, dispatch } = useStore();

  const handleProfileSelect = useCallback((profile) => {
    dispatch(actions.app.currentProfileChange(profile));  // this will cause 
  }, [dispatch]);

  useEffect(() => {
    if (!!tracker && !!location) {
      document.title = title;

      tracker.trackPageView(location.pathname);
    }
  }, [title, location, tracker]);


  return (
    <PermissionedView>
      <div className="flex-grow-0">
        <NavBar
          user={state.user.data}
          profiles={state.profiles}
          currentProfile={state.currentProfile}
          onProfileSelect={handleProfileSelect}
        />
      </div>

      <div className="flex-grow-1 h-100">
        {children}
      </div>

      <ScrollViewMoreButton label="View more..." btnColor="warning" />
    </PermissionedView>
  );
}