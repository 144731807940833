import React, { forwardRef, useMemo } from 'react';

import { getRandomIdString } from '~/lib/Utils';
//import { useHoverDropdown } from '~/lib/Effects';


export default forwardRef(function NavigationDropdownItem({
  label,
  children,
  right = false,
  left = false,
  content,
  className = '',
  pages,  // to remove it from ...props
  ...props
}, externalRef) {
  const id = useMemo(getRandomIdString, []);

  // it has an issue with appearance after sub-menu item click
  //useHoverDropdown(dropdownRef);

  return (
    <li
      {...props}
      className={`nav-item dropdown ${right ? 'dropright' : left ? 'dropleft' : ''} pt-2 text-uppercase ${className}`}
      ref={externalRef}
    >
      <a
        className="nav-link dropdown-toggle"
        id={`nav-${id}`}
        data-toggle="dropdown"
        href="#/"
      >
        {content || label}
      </a>
      <ul className="dropdown-menu bg-gprimary-desat-alt2" aria-labelledby={`nav-${id}`}>
        {children}
      </ul>
    </li>
  );
});