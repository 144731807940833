import React, { useCallback, useState, useEffect, useRef } from 'react';
import { useToasts } from 'react-toast-notifications';

export const NotificationType = {
  info: 'info',
  warning: 'warning',
  error: 'error',
  success: 'success'
};

const PanelClassTypes = {
  [NotificationType.info]: ['Info', 'info'],
  [NotificationType.warning]: ['Warning', 'warning'],
  [NotificationType.error]: ['Error', 'danger'],
  [NotificationType.success]: ['Success', 'success']
};

function getToastContent(title, message, type) {
  return (
    <span>
      {title || PanelClassTypes[type][0]}
      <div className={`mt-2 alert alert-${PanelClassTypes[type][1]}`}>
        {message}
      </div>
    </span>
  );
}

export function useNotifications() {
  const { addToast, updateToast } = useToasts();

  const [lastToastId, setLastToastId] = useState(0);

  const handleToastDismiss = useCallback((id) => {
    setLastToastId(lid => lid === id ? 0 : lid);
  }, [setLastToastId]);

  return useCallback(({
    type = NotificationType.info,
    message,
    title = null,
    replaceLast = false }) => {

    if (replaceLast && !!lastToastId) {
      updateToast(
        lastToastId,
        {
          appearance: type,
          content: getToastContent(title, message, type),
          onDismiss: handleToastDismiss
        },
        setLastToastId);
    } else {
      addToast(
        getToastContent(title, message, type),
        {
          appearance: type,
          onDismiss: handleToastDismiss
        },
        setLastToastId
      );
    }
  }, [addToast, updateToast, lastToastId, setLastToastId, handleToastDismiss]);
}

export function useErrorNotify(error) {
  const notify = useRef(useNotifications());

  useEffect(() => {
    if (!!error) {
      notify.current({
        type: NotificationType.error,
        message: error
      });
    }
  }, [error, notify]);
}