import React, { useCallback, useEffect, forwardRef } from 'react';

import { stopPreventEvent, eventTargetBlur, noop } from '~/lib/Utils';


export default forwardRef(function HelpItem({
  index,
  name,
  content,
  selectedIndex,
  onHelpSelect = noop
}, externalRef) {

  const handleHelpClick = useCallback((e) => {
    stopPreventEvent(e);
    eventTargetBlur(e);

    onHelpSelect(index);
  }, [index, onHelpSelect]);

  useEffect(() => {
    if (selectedIndex === index) {
      // WARNING! when selected onOalSelected() is called twice: after click and when selected is changed
      // in this particular application this is fine, but must be resolved with usePrev(url) in an ideal case
      onHelpSelect(index);
    }
  }, [index, selectedIndex, onHelpSelect]);

  const selected = selectedIndex === index;

  return (
    <div
      className={`list-group-item p-3 pointable ${selected ? 'active' : ''}`}
      onClick={handleHelpClick}
      ref={externalRef}
    >
      <small className={selected ? 'font-weight-bold' : ''}>{content || name}</small>
    </div>
  );
});