import React from 'react';
import config from './data/legal.json';

// context object
export const Configuration = React.createContext();

export default function ConfigurationProvider({ children }) {
  return (
    <Configuration.Provider value={config}>
      {children}
    </Configuration.Provider>
  );
}

