import React from 'react';
import DOMPurify from 'dompurify';
import { StyleSheet, View, Text, Image as PdfImage } from '@react-pdf/renderer'

const Styles = StyleSheet.create({
  title: {
    marginBottom: 120,
    color: '#FFF',
    image: (titleLength, descriptionLength) => ({
      position: 'absolute',
      top: 0,
      left: -6 * (Math.floor(titleLength / 40) + Math.floor(descriptionLength / 100)),
      right: -6 * (Math.floor(titleLength / 40) + Math.floor(descriptionLength / 100)),
      height: 160 + 19 * (Math.floor(titleLength / 40) + Math.ceil(descriptionLength / 100)) // each ~100 characters mean one line of text
    }),
    name: {
      fontSize: 22,
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: 16
    },
    description: (titleLength, descriptionLength) => ({
      opacity: 0.8,
      textAlign: descriptionLength < 90 ? 'center' : 'justify',
      width: '100%'
    })
  }
});

export default function Title({
  name,
  description
}) {
  return (
    <React.Fragment>
      <PdfImage
        src="/assets/img/illustrations/toolkit/illustration-intervention-welcome.png"
        style={Styles.title.image(name?.length || 0, description?.length || 0)}
      />
      <View style={Styles.title}>
        <Text style={Styles.title.name}>
          {name}
        </Text>
        <Text style={Styles.title.description(name?.length || 0, description?.length || 0)}>
          {DOMPurify.sanitize(description || '')}
        </Text>
      </View>
    </React.Fragment>
  );
}