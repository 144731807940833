import React from 'react';

// Components
import { StyleSheet, View, Image as PdfImage } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

const Styles = StyleSheet.create({
  sdg: {
    display: 'flex',
    flexDirection: 'row',
    paddingRight: 40,
    image: {
      width: 48,
      marginRight: 2,
      marginBottom: 2
    }
  }
});

function getSdgNumber(sdg) {
  return Array.from(`0${sdg}`).takeRight(2).join('');
}

export default function Sdgs({
  nbsSdg
}) {
  return (
    <Paragraph title="SDGs">
      <View style={Styles.sdg}>
        {nbsSdg?.map(({ sdg }, indx) => (
          <PdfImage
            key={`sdg-${indx}`}
            src={`/assets/img/illustrations/toolkit/sdg/E-WEB-Goal-${getSdgNumber(sdg)}.png`}
            style={Styles.sdg.image}
          />
        ))}
      </View>
    </Paragraph>
  );
}