import React, { useCallback } from 'react';

import { getPdfBlob } from './InterventionPdfDocument';
import intervention from './intervention.json';

export default function InterventionPage() {
  const handleInterventionPdfView = useCallback(async () => {
    const pdf = await getPdfBlob(intervention);
    const pdfUrl = URL.createObjectURL(pdf)

    const pdfWindow = window.open(pdfUrl, '_blank');

    // if you uncomment these lines it won't be able to download the document after view
    // if (!!pdfWindow) {
    //   pdfWindow.addEventListener('load', () => URL.revokeObjectURL(pdfUrl));
    // }
  }, []);

  return (
    <button className="btn btn-gprimary" onClick={handleInterventionPdfView}>
      PDF
    </button>
  )
}