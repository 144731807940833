import React from 'react';
import DOMPurify from 'dompurify';
import { formatArea } from '~/lib/Utils';

// Components
import { Text } from '@react-pdf/renderer';
import Paragraph from '../../Paragraph';

export default function Suitability({
  suitabilityMapSummary
}) {
  return (
    <Paragraph title="NBS Suitability Mapping">
      <Text>{DOMPurify.sanitize(suitabilityMapSummary?.msg || '')}</Text>

      {!!suitabilityMapSummary?.selectedArea && (
        <Text>{formatArea(suitabilityMapSummary?.selectedArea)}</Text>
      )}

      {!!suitabilityMapSummary?.suitableArea && (
        <Text>{formatArea(suitabilityMapSummary?.suitableArea)}</Text>
      )}
    </Paragraph>
  );
}