import React from 'react';

// Components
import Section from '../Section';

import Modelling from './Design/Modeling';
import Maintenance from './Design/Maintenance';
import Lifetime from './Design/Lifetime';
import Size from './Design/Size';
import Implementation from './Design/Implementation';
import TimeTillBenefits from './Design/TimeTillBenefits';
import CoCreation from './Design/CoCreation';
import Cautions from './Design/Cautions';

export default function Design({
  intervention
}) {
  return (
    <Section title="Design Inmplementation">
      {!!intervention?.modelling && (
        <Modelling modelling={intervention.modelling} />
      )}

      {!!intervention?.nbsMaintenance && (
        <Maintenance nbsMaintenance={intervention.nbsMaintenance} />
      )}

      {!!intervention?.nbsLifetime && (
        <Lifetime nbsLifetime={intervention.nbsLifetime} />
      )}

      {(!!intervention?.sizeShort || !!intervention?.size) && (
        <Size size={intervention.size} sizeShort={intervention.sizeShort} />
      )}

      {(!!intervention?.implementationTime || !!intervention?.implementationNotes) && (
        <Implementation
          implementationNotes={intervention.implementationNotes}
          implementationTime={intervention.implementationTime}
        />
      )}

      {!!intervention?.timeTillBenefits && (
        <TimeTillBenefits timeTillBenefits={intervention.timeTillBenefits} />
      )}

      {!!intervention?.coCreation && (
        <CoCreation coCreation={intervention.coCreation} />
      )}

      {!!intervention.cautions && (
        <Cautions cautions={intervention.cautions} />
      )}
    </Section>
  );
}