import { useEffect, useState, useCallback, useRef, useMemo } from 'react';

import { is, noop, downloadFile, stopPreventEvent, openUrlNoOpener, eventTargetBlur } from './Utils';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

export function noUnmountAction() {
  return ({ ignore: true });
}

export function useNavigate() {
  const history = useHistory();

  return useCallback((url) => {
    if (!!url && url.startsWith('/')) {
      history.push(url);
    } else {
      openUrlNoOpener(url);
    }
  }, [history]);
}


export function useDragAndDrop(placeholderRef, callback) {
  const [file, setFile] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(
    () => {
      async function handlenDrop(e) {
        stopPreventEvent(e);
        if (e.dataTransfer.files.length) {
          const file = e.dataTransfer.files[0];

          const result = await downloadFile(file, callback);

          setFile(file);
          setContent(result);
        }
      }

      function handleDragOver(e) {
        stopPreventEvent(e);

        e.dataTransfer.dropEffect = 'copy';
      }

      if (placeholderRef.current) {
        const ref = placeholderRef.current;

        ref.addEventListener('drop', handlenDrop, false);
        ref.addEventListener('dragover', handleDragOver, false);

        return () => {
          ref.removeEventListener('drop', handlenDrop);
          ref.removeEventListener('dragover', handleDragOver);
        }
      }
    },
    [placeholderRef, callback]
  );

  return ({ file, content });
}

export function useDownloadFile(file, callback) {
  const [result, setResult] = useState(null);

  useEffect(() => {
    if (!!file) {
      downloadFile(file, callback)
        .then(res => setResult(res))
        .catch(console.log);
    }
  }, [file, callback]);

  return result;
}

export function useScrollOnTopOnMount() {
  useEffect(() => window.scrollTo(0, 0), []);
}

export function useCurrentLocationCoordinates() {
  const [coordinates, setCoordinates] = useState([50.846056, 4.353358] /* Brusseles */);
  const [zoom, setZoom] = useState(4);
  const [local, setLocal] = useState(null);

  useEffect(
    () => {
      const processGeolocation = async pos => {
        setCoordinates([pos.coords.latitude, pos.coords.longitude]);
        setZoom(15);
        setLocal(true);
      }

      if (navigator.geolocation
        && (window.location.protocol.startsWith('https') || window.location.hostname === 'localhost')) {
        navigator.geolocation.getCurrentPosition(processGeolocation);
      } else {
        setLocal(false);
      }
    }, []);

  return [coordinates, zoom, local];
}

export function useNoConsoleWarnings() {
  // this hook prevents to spam into a console with warnings like "componentWillMount" or "ResponsiveContainer is too smart"
  useEffect(() => {
    const original = console.log;
    console.warn = noop;
    return () => console.log = original;
  });
}

export function useOneOfTwo(one, two) {
  const [result, setResult] = useState(null);

  useEffect(() => { setResult(one); }, [one]);
  useEffect(() => { setResult(two); }, [two]);

  return result;
}

export function useOneOrAnother(one, two) {
  return useMemo(() => {
    return one || two;
  }, [one, two]);
}

export function useOneOfThree(one, two, three) {
  const [result, setResult] = useState(null);

  useEffect(() => { setResult(one); }, [one]);
  useEffect(() => { setResult(two) }, [two]);
  useEffect(() => { setResult(three) }, [three]);

  return result;
}

export function useHoverDropdown(dropdownRef) {
  useEffect(() => {
    const node = dropdownRef.current;

    if (!!node) {
      window.jQuery(node).hover(function () {
        const dropdownMenu = window.jQuery(this).children('.dropdown-menu');

        if (dropdownMenu.is(':visible')) {
          dropdownMenu.parent().toggleClass('show');
        }
      });

      return () => {
        window.jQuery(node).off('mouseenter mouseleave');
      }
    }
  }, [dropdownRef]);
}

export function useMultiDropdown(dropdownRef) {
  useEffect(() => {
    const node = dropdownRef.current;

    if (!!node) {
      window.jQuery(node).click(function (e) {
        stopPreventEvent(e);

        if (window.jQuery(this).next('ul').is(':hidden')) {
          window.jQuery(this).parent().parent().find('ul').toggle(false);
          window.jQuery(this).next('ul').fadeToggle(200);
        } else {
          window.jQuery(this).next('ul').toggle(false);
        }
      });

      return () => {
        window.jQuery(node).off('click');
      }
    }
  }, [dropdownRef]);
}

export function useDebounced(value, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      const timeoutId = setTimeout(() => setDebouncedValue(value), delay);

      return () => clearTimeout(timeoutId);
    },
    [value, delay]
  );

  return debouncedValue;
}

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  const refPrev = useRef();

  // Store current value in ref
  useEffect(
    () => {
      if (ref.current !== value) {
        // protection against multiple re-render triggering
        refPrev.current = ref.current;
        ref.current = value;
      }
    }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return refPrev.current;
}

export function useWindowEvent(event, callback) {
  useEffect(() => {
    window.addEventListener(event, callback, true);

    return () => window.removeEventListener(event, callback, true);
  }, [event, callback]);
};

export function useElementEvent(element, event, callback) {
  useEffect(() => {
    !!element && element.addEventListener(event, callback, true);

    return () => {
      !!element && element.removeEventListener(event, callback, true);
    }
  }, [element, event, callback]);
};

export function useBrowserWindowChanges(callback) {
  const debouncedWindowScroll = useDebouncedCallback(callback, 100);

  useWindowEvent('scroll', debouncedWindowScroll.callback);
  useWindowEvent('resize', debouncedWindowScroll.callback);
  useElementEvent(document, 'load', debouncedWindowScroll.callback);

  useEffect(() => {
    return () => {
      if (!!debouncedWindowScroll) {
        debouncedWindowScroll.cancel();
      }
    }
  }, [debouncedWindowScroll]);

  const devicePixelRatio = useRef(window.devicePixelRatio || 1);

  useEffect(() => {
    let intervalId = null

    intervalId = window.setInterval(() => {
      const dpr = window.devicePixelRatio;
      if (devicePixelRatio.current !== dpr) {
        devicePixelRatio.current = dpr;

        callback(dpr);
      }
    }, 500);

    return () => {
      if (!!intervalId) {
        window.clearInterval(intervalId);
        intervalId = null;
      }
    }
  }, [callback]);
}

export function useAvailableHeight(initialHeight, containerWrapperRef) {

  const [containerHeight, setContainerHeight] = useState(window.innerHeight - initialHeight);


  const handleWindowChange = useCallback(() => {
    if (!!containerWrapperRef.current) {
      setContainerHeight(window.innerHeight - containerWrapperRef.current.getBoundingClientRect().top - 1);
    }
  }, [containerWrapperRef]);

  useBrowserWindowChanges(handleWindowChange);

  return containerHeight;
}

export function useOnce(once, cleanup) {
  useEffect(() => {
    once();

    if (cleanup) {
      return cleanup
    }
  }, []);
}

export function useInterval(callback = noop, delay, cancel) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    if (cancel) {
      clearInterval(id);
    }

    return () => clearInterval(id);
  }, [delay, cancel])
}

export function useTimeout(callback = noop, delay, cancel) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setTimeout(() => savedCallback.current(), delay);

    if (cancel) {
      clearTimeout(id);
    }

    return () => clearTimeout(id);
  }, [delay, cancel])
}

export function useScrollToElement(container, offset = 0) {
  const elementRef = useRef(null);

  const executeScroll = useCallback(() => {
    if (!!elementRef.current) {
      // container can be either a DOM node, or a React ref, or "true" when element's parent must be used
      const parent = (is.object(container) && ((!!container && container.current) || container))
        || (container === true && !!elementRef.current && elementRef.current.parentNode);

      if (!!parent) {
        parent.scrollTo({
          top: elementRef.current.offsetTop - 100 + offset,
          // WARNING! This magic number must be replaced with element ('s parent) top position inside the container
          // 100 works only on NBS Explorer page
          behavior: 'smooth'
        })
      } else {
        elementRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [container, offset]);

  return [executeScroll, elementRef];
};

export function useStopPreventCallback(callback, arg) {
  const handleCallback = useCallback((e) => {
    stopPreventEvent(e);
    eventTargetBlur(e);

    is.func(callback) && callback(arg);
  }, [callback, arg]);

  return handleCallback;
}