import React, { useCallback, useState, useRef } from 'react';

import { useBrowserWindowChanges } from '~/lib/Effects';

// Components
import PageHeader from '~/modules/main/components/App/PageHeader';
import ScrollOnTopButton from '~/components/scroll/ScrollOnTopButton';
import ProfileComponent, { ProfilePermissionType } from '~/components/profile/ProfileComponent';
import HelpItem from '../components/HelpPage/HelpItem';
import HelpDocument from '../components/HelpPage/HelpDocument';


export default function HelpPage() {
  const [helpSelectedIndex, setHelpSelectedIndex] = useState("webgis");

  const helpDocumentRef = useRef(null);

  const handleScrollDone = useCallback(() => {
    if (!!helpDocumentRef.current) {
      // NOTE. The iframe has a scrollable div that is not accessible because of cross domain
      // to manage this, use window.postMessage

      // WARNING. Does not work for some reasons
      const doc = window.jQuery(helpDocumentRef.current);
      doc.animate({ scrollTop: 0 }, 300);
    }
  }, []);

  const handleWindowChange = useCallback(() => {
    if (!!helpDocumentRef.current) {
      helpDocumentRef.current.style.minHeight = `${window.innerHeight}px`;
    }
  }, []);

  useBrowserWindowChanges(handleWindowChange);

  return (
    <React.Fragment>
      <PageHeader scope="help" title="Help" img="/assets/img/header/header-data-toolkit.jpg" />

      <div className="w-100 mb-3 text-center">
        <div className="list-group list-group-horizontal mt-3 d-flex flex-row justify-content-center">
          <ProfileComponent name="help.menu.webgis" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
            <HelpItem
              index="webgis"
              selectedIndex={helpSelectedIndex}
              name="WebGIS"
              onHelpSelect={setHelpSelectedIndex}
            />
          </ProfileComponent>

          <ProfileComponent name="help.menu.crowdsourcing" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
            <HelpItem
              index="crowdsourcing"
              selectedIndex={helpSelectedIndex}
              name="Crowdsourcing"
              onHelpSelect={setHelpSelectedIndex}
            />
          </ProfileComponent>

          <ProfileComponent name="help.menu.data_management" required={[ProfilePermissionType.read, ProfilePermissionType.execute]}>
            <HelpItem
              index="data_management"
              selectedIndex={helpSelectedIndex}
              name="Data Management"
              onHelpSelect={setHelpSelectedIndex}
            />
          </ProfileComponent>
        </div>
      </div>

      <div className="w-100">
        <ProfileComponent name={`help.menu.${helpSelectedIndex}`} required={[ProfilePermissionType.read]} ref={helpDocumentRef}>
          <HelpDocument />
        </ProfileComponent>

      </div>

      <ScrollOnTopButton
        distance={350}
        btnColor="primary"
        opacity={0.9}
        label="Back to the top"
        marginRight={11}
        onScrollDone={handleScrollDone}
      />

    </React.Fragment>
  );
}
