import React from 'react';
import styled from 'styled-components';

const BackgroundStyled = styled.div`
  background-image: url(${/* sc-value */ ({ src }) => src});
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  min-height: calc(100vh - 5.3rem);
`;

const CardRoundedStyled = styled.div`
  border-radius: 50% !important;
  border: 1px solid var(--gprimary-desat-alt) !important;
  
  height: 40rem;
  width: 40rem !important;
  max-width: calc(100vw);
  max-height: calc(100vw);

  box-shadow: 0 1.5rem 4rem rgb(22 28 45 / 15%) !important;

  .form-control.form-control-flush {
    background-color: var(--gprimary-desat-alt3);
    border-radius: 50rem;
    padding-left: 2rem;
  }

  .form-label-group > .form-control-flush ~ label {
    padding-left: 2rem !important;
    padding-right: 1rem !important;
  }
`;

export default function AuthContainer({ backgroundImageSrc, children: content }) {
  return (
    <section>
      <div className="container-fluid d-flex flex-column px-0">
        <BackgroundStyled className="d-flex flex-row justify-content-center align-items-center" src={backgroundImageSrc}>
          <CardRoundedStyled className="card bg-gprimary-desat-alt2 d-flex flex-column justify-content-center align-tems-center">
            <div className="card-body d-flex flex-column align-items-center">
              {content}
            </div>
          </CardRoundedStyled>
        </BackgroundStyled>
      </div>
    </section>
  )
}