export const validation = {
  login: {
    email: { // here could be a path to a rule loaded from server (as an autogenerated from a server model)
      required: true
    },
    password: { // here could be a path to a rule loaded from server (as an autogenerated from a server model)
      required: true
    }
  },
  signup: {
    email: {
      required: true,
      pattern: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
      max: 200
    },
    password: {
      required: true
    },
    displayName: {
      required: true,
      max: 100
    },
    profile: {
      required: true,
      min: 1
    },
    organizationName: {
      max: 100
    },
    interest: {}

  }
};