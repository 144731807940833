import React, { useMemo, forwardRef } from 'react';


import { useLocation } from 'react-router-dom';

// Components
import InternalExternalNavLink from '~/components/link/InternalExternalNavLink';

const PAGE_MAP = ({
  'home': '/home',
  'oal-explorer': '/oal/explorer',
  'nbs-explorer': '/nbs/explorer'
});

export default forwardRef(function NavigationItem({
  url,
  label,
  content,
  pages
}, externalRef) {
  const { pathname } = useLocation();

  const to = useMemo(() => {
    if (!!pages) {
      const defaultPage = pages.find(p => p.page === '*');

      const page = pages.find(p => PAGE_MAP[p.page] === pathname);

      if (!!defaultPage) {
        return (!!page && page.url) || defaultPage.url;
      }
    }

    return url;
  }, [url, pages, pathname]);

  return (
    <li
      className="nav-item"
      ref={externalRef}
    >
      <InternalExternalNavLink to={to} className="text-uppercase">
        {content || label}
      </InternalExternalNavLink>
    </li>
  );
});