import React, { createContext, useEffect, useState, useContext } from 'react';
import { useInterval } from '../lib/Effects';

const Owa = createContext();

function createOwaEvent(tracker, type, property, value) {
  const evt = tracker.makeEvent();
  evt.setEventType(type);
  evt.set(property, value);

  return evt;
}

function OpenWebAnalyticsProvider({
  siteId,
  trackPageView,
  trackClicks,
  trackAction,
  children
}) {

  const [owaTracker, setOwaTracker] = useState(null);
  const [owaInitialized, setOwaInitialized] = useState(false);

  useInterval(
    () => {
      setOwaInitialized(status => {
        if (status) {
          return;
        } else if (!!window.OWA) {
          window.OWA.setSetting('baseUrl', process.env.REACT_APP_OWA_BASEURL);

          return true;
        } else {
          return status;
        }
      });
    },
    100,
    owaInitialized
  );

  useEffect(() => {
    if (!owaInitialized) {
      return;
    }

    setOwaTracker(owaTracker => {

      if (!!owaTracker && owaTracker.siteId === siteId) {
        return owaTracker;
      } else {
        const tracker = new window.OWA.tracker();

        tracker.setSiteId(siteId);
        trackPageView && tracker.trackPageView();
        trackClicks && tracker.trackClicks();

        return ({
          siteId,
          trackPageView: (url) => tracker.trackPageView(url),
          trackAction: ({ group, name, description, value }) => tracker.trackAction(group, name, description, value),
          trackEvent: ({ type, property, value }) => tracker.trackEvent(createOwaEvent(tracker, type, property, value))
        });
      }
    })
  }, [siteId, trackPageView, trackClicks, owaInitialized]);

  return (
    <Owa.Provider value={owaTracker}>
      {children}
    </Owa.Provider>
  );
}

function useOwa() {
  return useContext(Owa);
}

export {
  Owa,
  OpenWebAnalyticsProvider,
  useOwa
}

/*
return ({
          siteId,
          trackLocationChanged: trackAction
            ? (location) => tracker.trackAction('Navigate', location.pathname, '', 1)
            : noop
        });
        */